import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import { openInfoDialog } from '../../../store/dialogs';

function InfoButton(props) {
  const { title, content, ...restProps } = props;
  const dispatch = useDispatch();

  const handleInfoClick = () => {
    dispatch(openInfoDialog({ title, content }));
  };

  return (
    <IconButton
      sx={{ height: 30, width: 30 }}
      onClick={handleInfoClick}
      {...restProps}>
      <FontAwesomeIcon size='sm' icon={['fal', 'info-circle']} />
    </IconButton>
  );
}

InfoButton.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
};

export default InfoButton;
