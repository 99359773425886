import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import head from 'lodash/head';
import map from 'lodash/map';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { RANGES } from '../../components/charts/selectors/SelectRange';
import { setLoader } from '../pages';
import { buildAsyncReducers } from '../thunkTemplate';
import { kiosk } from '../initialState';

const getKioskData = createAsyncThunk(
  'kiosk/getKioskData',
  async (_, { dispatch, getState, requestId }) => {
    const { kioskCode, type, current, currentRequestId, loading } =
      getState().kiosk;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      let resource = null;
      let sites = null;

      const payload = await new WebAPIClient().GET(
        `/kiosk/${type}/${kioskCode}`
      );
      let images = await new WebAPIClient().GET(
        `/kiosk/images/${type}/${kioskCode}`
      );

      const caseStudy = head(
        remove(images, (image) => {
          return image.filename.startsWith('case-study.');
        })
      );
      const logo = head(
        remove(images, (image) => {
          return image.filename.startsWith('logo.');
        })
      );

      if (type === 'organization') {
        resource = get(payload, 'organization', {});
        sites = sortBy(get(payload, 'sites', []), 'name');
      } else if (type === 'site') {
        const site = get(payload, 'site', {});
        resource = site;
        sites = [site];
      }

      return {
        lastUpdate: dayjs(),
        validLicense: true,
        resource,
        sites,
        loggers: get(payload, 'loggers', []),
        meters: sortBy(get(payload, 'meters', []), 'name'),
        inverters: get(payload, 'inverters', []),
        images: {
          caseStudy,
          logo,
          data: images,
          selectedIdx: 0,
        },
        current: {
          ...current,
          timezone: { ...resource.timezone },
        },
      };
    } catch (err) {
      errorResponseToastr(err);
      return { validLicense: false };
    } finally {
      dispatch(setLoader(false));
    }
  }
);

const getLastMonthData = createAsyncThunk(
  'kiosk/getLastMonthData',
  async (meters, { dispatch, getState, requestId }) => {
    const { kioskCode, type, lastMonth, currentRequestId, loading } =
      getState().kiosk;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }
    try {
      dispatch(showLoading());
      let month = dayjs().subtract(1, 'month').startOf('month');

      let _rawData = await Promise.all(
        map(meters, (meter) =>
          new WebAPIClient().GET(
            `/kiosk/${type}/${kioskCode}/${meter.meter_id}/${month.format(
              'YYYY-MM'
            )}`
          )
        )
      );

      return { lastMonth: { ...lastMonth, rawData: _rawData } };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getKioskChartData = createAsyncThunk(
  'kiosk/getKioskChartData',
  async (newRange, { dispatch, getState, requestId }) => {
    const {
      kioskCode,
      type,
      meters,
      currentRequestId,
      loading,
      current,
      resource,
    } = getState().kiosk;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let defaultRange = RANGES[6];
    const end = dayjs().tz(resource.timezone.zone).endOf('day');
    const start = end.subtract(3, 'day').startOf('day');
    defaultRange = { ...defaultRange, start, end };

    let _range = newRange || defaultRange;
    try {
      dispatch(showLoading());
      let _type = type;
      if (_type === 'org') _type = 'organization';

      let rawData = await Promise.all(
        map(meters, (meter) =>
          new WebAPIClient().GET(
            `/kiosk/${_type}/${kioskCode}/${
              meter.meter_id
            }/${_range.start.unix()}/${_range.end.unix()}`
          )
        )
      );
      return { current: { ...current, rawData, range: _range } };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const { actions, reducer } = createSlice({
  name: 'kiosk',
  initialState: kiosk,
  reducers: {
    setKioskTypeCode: (state, { payload }) => {
      return {
        ...state,
        kioskCode: payload.kioskCode,
        type: payload.type,
      };
    },
    setImageIndex: (state, { payload }) => {
      let idx = null;

      if (state.images.selectedIdx === payload - 1) {
        idx = 0;
      } else {
        idx = state.images.selectedIdx + 1;
      }

      return {
        ...state,
        images: {
          ...state.images,
          selectedIdx: idx,
        },
      };
    },
    setSelectedEnergy: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedEnergy: payload,
        },
      };
    },
    setCostSavings: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          costSavings: payload,
        },
      };
    },
    setSlideHeight: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          slideHeight: payload,
        },
      };
    },
    setCurrentTimezone: (state, { payload }) => {
      return {
        ...state,
        current: { ...state.current, timezone: payload },
      };
    },
    setLastMonthDate: (state, { payload }) => {
      return {
        ...state,
        lastMonth: { ...state.lastMonth, date: payload },
      };
    },
    handleSuccessKiosk: (state, { payload }) => {
      let meters = cloneDeep(state.meters);
      let range = cloneDeep(state.current.range);
      let rawData = cloneDeep(state.current.rawData);

      const orgId = payload.org_id;
      const loggerId = payload.logger_id;
      let loggerMeters = filter(meters, { logger_id: loggerId, org_id: orgId });

      forEach(loggerMeters, (meter) => {
        const value = payload[`kW_Demand_System_Now_M${meter.parent_index}`];
        const recordDayjs = dayjs.unix(payload.timestamp);
        if (
          typeof value === 'number' &&
          range?.end.isSame(recordDayjs, 'day')
        ) {
          let meterRawData = head(
            remove(rawData, { meter_id: meter.meter_id })
          );
          if (meterRawData) {
            meterRawData.records.push({
              timestamp: payload.timestamp,
              value,
            });
            rawData.push(meterRawData);
          }
        }
      });

      return { ...state, current: { ...state.current, rawData } };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getKioskData,
      getLastMonthData,
      getKioskChartData,
    ]);
  },
});

const {
  setKioskTypeCode,
  setImageIndex,
  setSelectedEnergy,
  setCostSavings,
  setSlideHeight,
  setCurrentTimezone,
  setLastMonthDate,
  handleSuccessKiosk,
} = actions;

export {
  setKioskTypeCode,
  getKioskData,
  getLastMonthData,
  getKioskChartData,
  setImageIndex,
  setSelectedEnergy,
  setCostSavings,
  setSlideHeight,
  setCurrentTimezone,
  setLastMonthDate,
  handleSuccessKiosk,
};
export default reducer;
