import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import useLogger from '../../../../store/hooks/useLogger';
import useMeter from '../../../../store/hooks/useMeter';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import useView from '../../../../store/hooks/useView';

import ProductionSums from './ProductionSums';
import MeterInfo from './MeterInfo';
import LatestData from './LatestData';
import ViewStack from '../../../../components/ViewStack';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import MeterProfileChart from '../../../../components/charts/preview/MeterProfileChart';

const TREND = 'trend';
const ALARMS = 'alarms';
const LATEST = 'latest';
const VIEWS = [TREND, ALARMS, LATEST];

function MeterDashboard() {
  const { id } = useSelector((state) => state.pages.meter);
  const meter = useMeter(id);
  const logger = useLogger(meter.logger_id);
  const alarms = useDeviceAlarms(id);
  const selectedView = useView(VIEWS);

  if (!logger.active) {
    return (
      <Box sx={{ my: 4, width: '100%' }}>
        <Typography align='center' color='textSecondary'>
          Meter Inactive
        </Typography>
      </Box>
    );
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ width: '100%', pt: 1, px: 1, mb: 6 }}>
      <Grid size={{ xs: 12, md: 4 }}>
        <MeterInfo logger={logger} meter={meter} alarms={alarms} />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <ProductionSums logger={logger} meter={meter} />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ViewStack selectedView={selectedView} views={VIEWS} />
      </Grid>
      {selectedView === TREND && (
        <Grid size={{ xs: 12, width: '100%' }}>
          <Card raised sx={{ p: 1 }}>
            <MeterProfileChart
              meterId={meter.meter_id}
              hideAxisLabels={false}
            />
          </Card>
        </Grid>
      )}
      {selectedView === ALARMS && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={meter} alarms={alarms} />
        </Grid>
      )}
      {selectedView === LATEST && <LatestData />}
    </Grid>
  );
}

export default MeterDashboard;
