import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import remove from 'lodash/remove';

import {
  EditingState,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import Box from '@mui/material/Box';

import useVerifyOrgRole from '../../store/hooks/useVerifyOrgRole';
import { showLoading, hideLoading } from '../../store/app';
import ROLES from '../../constants/roles';
import WebAPIClient from '../../api';
import BaseTable from '../table/tables/BaseTable';
import ComponentLoader from '../Loaders/ComponentLoader';
import { ReportFileDownloadTypeProvider } from '../table/providers/ReportFileDownloadTypeProvider';
import { EditCell } from '../table/cells';
import BaseDialog from './BaseDialog';

function ReportFilesDialog(props) {
  const { reportId, setReportId } = props;
  const dispatch = useDispatch();

  const reports = useSelector((state) => state.reports.data);

  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({});
  const [files, setFiles] = useState([]);
  const isOrgAdmin = useVerifyOrgRole(report?.org_id, ROLES.ADMIN.value);

  const [columns] = useState([
    { title: 'filename', name: 'filename', width: 1 },
  ]);

  useEffect(() => {
    if (reportId) {
      setReport(find(reports, { report_id: reportId }));
    }
  }, [reportId, reports]);

  useEffect(() => {
    async function fetchFiles(report) {
      setLoading(true);
      const files = await new WebAPIClient().GET(
        `/resource/report_files/${report.device_id}`,
        {
          period: report.period,
        }
      );
      setFiles(
        map(files, (filename) => ({
          filename,
          resourceId: report.device_id,
          period: report.period,
        }))
      );
      setLoading(false);
    }
    if (!isEmpty(report)) {
      fetchFiles(report);
    }
  }, [report]);

  const handleClose = () => {
    setReportId('');
    setReport({});
  };

  const handleDelete = async (row) => {
    let updatedFiles = cloneDeep(files);
    dispatch(showLoading());
    await new WebAPIClient().DELETE(
      `/resource/report_files/${row.resourceId}`,
      {
        period: row.period,
        filename: row.filename,
      }
    );
    remove(updatedFiles, row);
    setFiles(updatedFiles);
    dispatch(hideLoading());
  };

  return (
    <BaseDialog id={reportId} title='Report PDFs' handleClose={handleClose}>
      <Box sx={{ width: '100%', maxWidth: 600, minHeight: 50 }}>
        {loading ? (
          <ComponentLoader height='50px' width='50px' />
        ) : (
          <BaseTable
            headerRow={false}
            rows={files}
            columns={columns}
            maxWidth={600}
            editColumn={
              <TableEditColumn
                width={42}
                showEditCommand
                cellComponent={(props) => (
                  <EditCell
                    disabled={!isOrgAdmin}
                    tooltipText='Delete'
                    handleClick={() => handleDelete(props.row)}
                    icon={['fal', 'trash']}
                    {...props}
                  />
                )}
              />
            }>
            <ReportFileDownloadTypeProvider for={['filename']} />
            <SortingState
              defaultSorting={[{ columnName: 'filename', direction: 'asc' }]}
            />
            <PagingState defaultCurrentPage={0} pageSize={10} />
            <EditingState />
            <IntegratedSorting />
          </BaseTable>
        )}
      </Box>
    </BaseDialog>
  );
}

export default ReportFilesDialog;
