import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import PerformanceIndexChart from '../charts/PerformanceIndexChart';

function ProductionIndexBar(props) {
  const { actual, expected, label, loading } = props;
  return (
    <>
      <Grid size={{ xs: 5 }}>
        <Typography gutterBottom variant='body1' align='left'>
          {label}
        </Typography>
      </Grid>
      <Grid size={{ xs: 7 }}>
        <PerformanceIndexChart
          width={125}
          actual={actual}
          expected={expected}
          loading={loading}
        />
      </Grid>
    </>
  );
}

ProductionIndexBar.propTypes = {
  actual: PropTypes.number.isRequired,
  expected: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ProductionIndexBar;
