import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import cloneDeep from 'lodash/cloneDeep';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { toastr } from '../../components/CustomToast';

const getImages = createAsyncThunk(
  'images/getImages',
  async (resourceId, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      const images = await new WebAPIClient().GET(
        `/resource/images/${resourceId}`
      );
      return { [resourceId]: images };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getPresignedUrl = createAsyncThunk(
  'images/getPresignedUrl',
  async ({ resourceId, key }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, presignedUrls } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let urls = cloneDeep(presignedUrls);
    let resourceUrls = urls[resourceId];

    try {
      dispatch(showLoading());
      const imageUrl = await new WebAPIClient().GET(
        `/resource/images/${resourceId}/${key}`
      );

      if (resourceUrls) {
        resourceUrls[key] = imageUrl;
      } else {
        resourceUrls = { [key]: imageUrl };
      }

      urls[resourceId] = resourceUrls;
      return { presignedUrls: urls };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postImage = createAsyncThunk(
  'images/postImage',
  async (props, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const contentType = props.image.type || props.image.contentType;
      const body = {
        filename: props.filename,
        ContentType: contentType,
      };
      const config = {
        headers: {
          ContentType: contentType,
          Accept: 'application/json',
        },
      };
      const putUrl = await new WebAPIClient().POST(
        `/resource/images/${props.resource_id}`,
        body,
        config
      );

      await axios.put(putUrl, props.image, {
        headers: {
          'Content-Type': contentType,
        },
      });

      toastr.success({ title: 'Image saved' });
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const renameImage = createAsyncThunk(
  'images/renameImage',
  async (props, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      await new WebAPIClient().PUT(
        `/resource/images/${props.resourceId}`,
        props
      );

      toastr.success({ title: 'Image updated' });
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteImage = createAsyncThunk(
  'images/deleteImage',
  async (props, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().images;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      await new WebAPIClient().DELETE(
        `/resource/images/${props.resourceId}/${props.filename}`
      );

      toastr.success({ title: 'Image deleted' });
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getImages, postImage, renameImage, deleteImage, getPresignedUrl };
