import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import filter from 'lodash/filter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { openDialog } from '../../../../store/dialogs';
import { navigate } from '../../../../store/pages';
import useSensor from '../../../../store/hooks/useSensor';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import SensorChart from '../../dashboard/SensorChart';
import AlarmPreviewLink from '../../../AlarmPreviewLink';

function SensorProfileChart({ sensorId }) {
  const dispatch = useDispatch();

  const sensor = useSensor(sensorId);
  const meter = useMeter(sensor.meter_id);
  const site = useSite(meter.site_id);
  const alarms = useDeviceAlarms(sensorId);

  const handleIconClick = () => {
    dispatch(
      openDialog({
        type: 'sensor',
        mode: 'edit',
        id: sensor.sensor_id,
      })
    );
  };

  const handleSensorClick = () => {
    dispatch(
      navigate({
        page: 'sensor',
        id: sensor.sensor_id,
      })
    );
  };

  const activeAlarms = filter(alarms, { status: true });
  if (!sensor) return null;
  return (
    <Box
      sx={{
        px: 1,
        pt: 1,
        pb: 0.5,
        backgroundColor: 'background.paper',
        backgroundImage: (theme) => theme.vars.overlays[8],
        boxShadow: (theme) => theme.shadows[4],
        borderRadius: 1,
        width: '100%',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <AlarmPreviewLink
          alarms={activeAlarms}
          deviceName={sensor.name}
          handleClick={() => handleSensorClick()}
        />
        <IconButton onClick={() => handleIconClick()}>
          <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
        </IconButton>
      </Box>
      <SensorChart site={site} sensor={sensor} />
    </Box>
  );
}

SensorProfileChart.propTypes = {
  site: PropTypes.object,
  sensorId: PropTypes.string,
};

export default SensorProfileChart;
