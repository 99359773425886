import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import { styled } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabPanel from './TabPanel';
import { useLocation } from 'react-router';

export const ANALYSIS = 'analysis';
export const CONFIGURATION = 'configuration';
export const DASHBOARD = 'dashboard';
export const MAP = 'map';
export const ALARMS = 'alarms';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 60,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiTabs-scrollButtons': {
    color: theme.palette.primary.main,
  },
  minHeight: 'unset',
}));

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  minHeight: 'unset',
  '&.Mui-selected': { fontWeight: 'bold' },
}));

function TabBar(props) {
  const { tabs, selectedTab, children } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const appbarRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { drawerExpanded } = useSelector((state) => state.app);

  const changeTab = (event, value) => {
    dispatch(push(location.pathname + '#' + value));
  };

  const width = (() => {
    if (isMobile) return '100vw';
    const drawerWidth = drawerExpanded ? theme.drawerWidth : 0;
    return `calc(100vw - ${drawerWidth}px)`;
  })();

  const left = (() => {
    if (isMobile) return 0;
    const drawerWidth = drawerExpanded ? theme.drawerWidth : 0;
    return drawerWidth;
  })();
  const appbarHeight = appbarRef?.current?.offsetHeight || 34;
  const marginTop = isMobile ? '56px' : '64px';

  return (
    <Box>
      <AppBar
        ref={appbarRef}
        position='fixed'
        sx={{
          backgroundColor: theme.palette.grey[200],
          marginTop,
          width,
          left,
          top: 0,
        }}>
        <StyledTabs
          scrollButtons
          allowScrollButtonsMobile
          value={selectedTab}
          onChange={changeTab}
          indicatorColor='primary'
          textColor='primary'
          centered={!isMobile}
          variant={isMobile ? 'scrollable' : 'fullWidth'}>
          {map(tabs, (tab, idx) => {
            return (
              <StyledTab key={tab + idx} label={startCase(tab)} value={tab} />
            );
          })}
        </StyledTabs>
      </AppBar>

      <Box sx={{ height: appbarHeight + 'px ' }} />

      {map(children, (child) => {
        const tab = child.props.tab;
        return (
          <TabPanel key={tab} tab={tab} selectedTab={selectedTab}>
            {child}
          </TabPanel>
        );
      })}
    </Box>
  );
}

TabBar.propTypes = {
  tabs: PropTypes.array.isRequired,
  handleChangeTab: PropTypes.func,
  selectedTab: PropTypes.string,
  children: PropTypes.array,
};

export default TabBar;
