import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

import useSite from '../../../../store/hooks/useSite';
import useLogger from '../../../../store/hooks/useLogger';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import useView from '../../../../store/hooks/useView';

import ROLES from '../../../../constants/roles';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ViewStack from '../../../../components/ViewStack';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import DevicesGrid from './DevicesGrid';
import LoggerInfo from './LoggerInfo';
import LoggerProduction from './LoggerProduction';
import LiveData from './LiveData';

const DEVICES = 'devices';
const ALARMS = 'alarms';
const LIVE_DATA = 'live-data';
const VIEWS = [DEVICES, ALARMS];

function LoggerDashboard() {
  const { id } = useSelector((state) => state.pages.logger);
  const logger = useLogger(id);
  const isOrgAdmin = useVerifyOrgRole(logger?.org_id, ROLES.ADMIN.value);
  const site = useSite(logger.site_id);
  const { meters, inverters, alarms, loading } = useLoggerDevices(id);

  const [views, setViews] = useState(VIEWS);
  const selectedView = useView(VIEWS);

  useEffect(() => {
    if (isOrgAdmin) {
      setViews([...VIEWS, LIVE_DATA]);
    } else {
      setViews(VIEWS);
    }
  }, [isOrgAdmin]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ width: '100%', pt: 1, px: 1, mb: 6 }}>
      <Grid size={{ xs: 12, md: 4 }}>
        <LoggerInfo site={site} logger={logger} loading={loading} />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <CurrentGeneration
          loggers={[logger]}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <LoggerProduction meters={meters} />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ViewStack selectedView={selectedView} views={views} />
      </Grid>
      {selectedView === DEVICES && <DevicesGrid site={site} logger={logger} />}
      {selectedView === ALARMS && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={logger} alarms={alarms} />
        </Grid>
      )}
      {selectedView === LIVE_DATA && <LiveData />}
    </Grid>
  );
}

export default LoggerDashboard;
