import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function BenefitItem(props) {
  const { title, icon, children } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        backgroundImage: (theme) => theme.vars.overlays[8],
        boxShadow: (theme) => theme.shadows[4],
        borderRadius: 1,
        px: 2,
        pt: 1,
        pb: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        height: '100%',
      }}>
      <Typography variant='h6' align='center' fontWeight='bold'>
        {title}
      </Typography>
      <Typography variant='body2'>{children}</Typography>
      {icon}
    </Box>
  );
}
