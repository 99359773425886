import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import each from 'lodash/each';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import _range from 'lodash/range';
import dayjs from 'dayjs';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { navigate } from '../../../../store/pages';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import {
  addEstimatedProductionToChartData,
  clipProductionData,
} from '../../../../helpers/chart-data';
import ChartTooltip from './Tooltip';
import ComponentLoader from '../../../Loaders/ComponentLoader';

function MeterProfileChart({ meterId, hideAxisLabels = true }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { data: timeseries, loading: timeseriesLoading } = useSelector(
    (state) => state.timeseries
  );
  const { loading: meterLoading } = useSelector((state) => state.meters);
  const loading = timeseriesLoading || meterLoading;

  const meter = useMeter(meterId);
  const site = useSite(meter.site_id);

  const [chartData, setChartData] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  // set range
  useEffect(() => {
    const zone = site?.timezone?.zone;

    if (zone) {
      const _start = dayjs().tz(zone).startOf('day').subtract(1, 'day');
      setStart(_start);
      setEnd(_start.add(2, 'day'));
    }
  }, [site?.timezone?.zone]);

  useEffect(() => {
    if (!isEmpty(meter) && start && end) {
      let _data = map(_range(start?.unix(), end?.unix(), 900), (timestamp) => ({
        timestamp,
      }));

      const timeseriesData = timeseries.find((item) => {
        return item.device_id === meter.meter_id && item.data_type === 'recent';
      });

      each(timeseriesData?.data, (datapoint) => {
        let idx = findIndex(_data, { timestamp: datapoint.timestamp });
        if (idx >= 0) {
          _data[idx] = {
            ..._data[idx],
            [meter.meter_id]: clipProductionData(datapoint.value, site.org_id),
          };
        }
      });

      const estimatedData = timeseries.find((item) => {
        return (
          item.device_id === meter.meter_id && item.data_type === 'estimated'
        );
      });
      setChartData(
        addEstimatedProductionToChartData(
          _data,
          [estimatedData],
          site?.timezone?.zone
        )
      );
    }
  }, [site?.org_id, site?.timezone?.zone, meter, start, end, timeseries]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'meter',
        id: meterId,
        tab: 'analysis',
      })
    );
  };

  const height = isMobile ? 150 : 175;
  if (chartData.length === 0 || !start || !end || loading)
    return (
      <div style={{ height }}>
        <ComponentLoader height={50} width={50} />
      </div>
    );

  const startRef = start?.add(6, 'hour');
  const endRef = start?.add(30, 'hour');
  return (
    <ResponsiveContainer width='100%' height={height}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={chartData}
        margin={{
          top: 2,
          right: 0,
          left: 2,
          bottom: 0,
        }}>
        <YAxis
          hide={hideAxisLabels}
          mirror
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
          tickFormatter={(value) =>
            `${new Intl.NumberFormat('en-US', {
              maximumSignificantDigits: 3,
            }).format(value)} kW`
          }
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start?.unix(), end?.unix()]}
          hide
        />
        <ReferenceLine
          x={startRef.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>{startRef.format('MMM Do')}</Label>
          }
        />
        <ReferenceLine
          x={endRef.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>{endRef.format('MMM Do')}</Label>
          }
        />
        <Tooltip
          content={<ChartTooltip timezone={site.timezone} meter={meter} />}
        />
        <Area
          key={`area-${meter.meter_id}`}
          type='monotone'
          dataKey={meter.meter_id}
          fill={theme.veregy_colors.blue}
        />
        <Bar
          key={`bar-${meter.meter_id}`}
          dataKey={`${meter.meter_id}:estimated`}
          fill={theme.veregy_colors.lightgrey}
          stroke='transparent'
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

MeterProfileChart.propTypes = {
  meterId: PropTypes.string,
  hideAxisLabels: PropTypes.bool,
};

export default MeterProfileChart;
