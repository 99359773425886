import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import map from 'lodash/map';
import each from 'lodash/each';
import range from 'lodash/range';
import get from 'lodash/get';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import { alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  addEstimatedProductionToChartData,
  clipProductionData,
} from '../../../../helpers/chart-data';
import { navigate } from '../../../../store/pages';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import ComponentLoader from '../../../Loaders/ComponentLoader';
import ChartTooltip from './Tooltip';

function LoggerProfileChart(props) {
  const { orgId, loggerId, timezone } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { data: timeseries, loading: timeseriesLoading } = useSelector(
    (state) => state.timeseries
  );

  const {
    meters,
    inverters,
    loading: devicesLoading,
  } = useLoggerDevices(loggerId);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [meterIds, setMeterIds] = useState([]);
  const [inverterIds, setInverterIds] = useState([]);
  const loading = timeseriesLoading || devicesLoading;

  useEffect(() => {
    setMeterIds(sortBy(meters, 'parent_index').map((meter) => meter.meter_id));
  }, [meters]);

  useEffect(() => {
    setInverterIds(
      sortBy(inverters, 'parent_index').map((inverter) => inverter.inverter_id)
    );
  }, [inverters]);

  useEffect(() => {
    const zone = timezone?.zone || 'UTC';
    const _start = dayjs().tz(zone).startOf('day').subtract(1, 'day');
    const _end = _start.add(2, 'day');
    setStart(_start);
    setEnd(_end);
  }, [timezone?.zone]);

  useEffect(() => {
    const recentMeterTimeseries = timeseries.filter((item) => {
      return meterIds.includes(item?.device_id) && item?.data_type === 'recent';
    });

    const recentInverterTimeseries = timeseries.filter((item) => {
      return (
        inverterIds.includes(item?.device_id) && item?.data_type === 'recent'
      );
    });

    const estimatedMeterTimeseries = timeseries.filter((item) => {
      return (
        meterIds.includes(item?.device_id) && item?.data_type === 'estimated'
      );
    });

    if (!start || !end || recentMeterTimeseries.length === 0) {
      return;
    }

    let _data = map(range(start.unix(), end.unix(), 900), (timestamp) => ({
      timestamp,
    }));

    _data = map(_data, (record) => {
      const timestamp = get(record, 'timestamp');
      each(recentMeterTimeseries, (item) => {
        let meterPoint = find(item.data, { timestamp });
        if (meterPoint) {
          record[item.device_id] = clipProductionData(
            get(meterPoint, 'value'),
            orgId
          );
        }
      });

      return { ...record };
    });

    _data = map(_data, (record) => {
      const timestamp = get(record, 'timestamp');
      each(recentInverterTimeseries, (item) => {
        let inverterPoint = find(item.data, { timestamp });
        if (inverterPoint) {
          record[item.device_id] = get(inverterPoint, 'value');
        }
      });

      return { ...record };
    });

    setData(
      addEstimatedProductionToChartData(
        _data,
        estimatedMeterTimeseries,
        timezone?.zone
      )
    );
  }, [start, end, timezone?.zone, orgId, meterIds, inverterIds, timeseries]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'logger',
        id: loggerId,
        tab: 'analysis',
      })
    );
  };

  if (!start || !end || loading)
    return (
      <div
        style={{
          height: '200px',
        }}>
        <ComponentLoader height='75px' width='75px' />
      </div>
    );

  let startReference = start.add(6, 'hour');
  let endReference = start.add(30, 'hour');

  const height = isMobile ? 150 : 175;
  return (
    <ResponsiveContainer width='100%' height={height}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}>
        <YAxis hide domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]} />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start.unix(), end.unix()]}
          hide
        />
        <ReferenceLine
          x={startReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {startReference.format('MMM Do')}
            </Label>
          }
        />
        <ReferenceLine
          x={endReference.unix()}
          stroke={'transparent'}
          label={
            <Label position='insideTopLeft'>
              {endReference.format('MMM Do')}
            </Label>
          }
        />
        <Tooltip
          content={
            <ChartTooltip
              timezone={timezone}
              loggerId={loggerId}
              meters={meters}
              inverters={inverters}
            />
          }
        />
        {map(meterIds, (meterId, idx) => {
          return (
            <Area
              stackId='areas'
              key={`area-${meterId}`}
              type='monotone'
              dataKey={meterId}
              fill={alpha(
                theme.veregy_colors.blue,
                1 - idx * (1 / Math.max(meterIds.length, 4))
              )}
            />
          );
        })}
        {map(meterIds, (meterId, idx) => {
          return (
            <Bar
              stackId='estimated'
              key={`estimated-${meterId}`}
              dataKey={`${meterId}:estimated`}
              fill={alpha(theme.veregy_colors.lightgrey, 1 - idx * 0.2)}
              stroke='transparent'
            />
          );
        })}

        {map(inverterIds, (inverterId, idx) => {
          return (
            <Area
              dot={false}
              stackId='lines'
              key={`line-${inverterId}`}
              dataKey={inverterId}
              stroke={theme.veregy_colors.orange}
              fill='transparent'
              type='monotone'
              connectNulls
              strokeWidth={2}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

LoggerProfileChart.propTypes = {
  loggerId: PropTypes.string,
  timezone: PropTypes.object,
};

export default LoggerProfileChart;
