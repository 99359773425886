import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import WidgetLoader from '../../../../components/widgets/Loader';
import { dateStringToDayJS } from '../../../../helpers/date';
import dayjs from 'dayjs';
import AlarmPreviewLink from '../../../../components/AlarmPreviewLink';
function MeterInfo(props) {
  const { logger, meter, alarms } = props;
  const theme = useTheme();
  const [lastOk, setLastOk] = useState(null);
  const [lastFail, setLastFail] = useState(null);

  useEffect(() => {
    let _lastOk = get(logger, 'stats.last_ok_time');
    let _lastFail = get(logger, 'stats.last_fail_time');

    if (_lastOk) {
      if (typeof _lastOk === 'string') {
        setLastOk(dateStringToDayJS(_lastOk));
      } else {
        setLastOk(dayjs.unix(_lastOk));
      }
    }

    if (_lastFail) {
      if (typeof _lastFail === 'string') {
        setLastFail(dateStringToDayJS(_lastFail));
      } else {
        setLastFail(dayjs.unix(_lastFail));
      }
    }
  }, [logger]);

  const online = get(logger, 'online', false);
  const color = (() => {
    if (!isEmpty(meter)) {
      if (online) {
        return theme.veregy_colors.green;
      } else return theme.veregy_colors.red;
    } else return theme.veregy_colors.grey;
  })();

  const lastUpdate = (() => {
    if (lastOk?.isValid() || lastFail?.isValid()) {
      return (online ? lastOk : lastFail).format('MM/DD/YYYY HH:mm z');
    }
    return '';
  })();

  return (
    <Card
      raised
      sx={{
        height: '100%',
        borderBottom: (theme) => `10px solid ${theme.veregy_colors.grey}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        pb: 0.5,
        px: 1,
        pt: 2,
      }}>
      <TextField
        id='last-update'
        label={`Last ${online ? 'OK' : 'Fail'} Time`}
        value={lastUpdate}
        fullWidth
        variant='standard'
        slotProps={{
          input: {
            readOnly: true,
            disableUnderline: true,
            fullWidth: true,
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
        <Tooltip
          title={get(logger, 'online', false) ? 'online' : 'offline'}
          placement='left'>
          <Icon
            sx={{
              color,
              fontSize: '1.5rem',
              height: 'inherit',
            }}>
            <div
              style={{
                backgroundColor: color,
                borderRadius: '50%',
                width: '1.3rem',
                height: '1.3rem',
              }}
            />
          </Icon>
        </Tooltip>
        <AlarmPreviewLink alarms={alarms} deviceName={meter?.name}>
          <Typography
            align='left'
            variant='h5'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}>
            {meter?.name}
          </Typography>
        </AlarmPreviewLink>
        {isEmpty(meter) && <WidgetLoader />}
      </Box>
    </Card>
  );
}

MeterInfo.propTypes = {
  logger: PropTypes.object,
  meter: PropTypes.object,
  alarms: PropTypes.array,
};

export default MeterInfo;
