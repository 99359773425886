import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { navigate } from '../../../../store/pages';
import { openDialog } from '../../../../store/dialogs';
import useSite from '../../../../store/hooks/useSite';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import SiteProfileChart from '../../../../components/charts/preview/SiteProfileChart';
import SitePerformance from './SitePerformance';

import CustomLink from '../../../../components/CustomLink';
import AlarmPreviewLink from '../../../../components/AlarmPreviewLink';

function SiteCard(props) {
  const { siteId, expectationType } = props;
  const dispatch = useDispatch();
  const site = useSite(siteId);
  const { alarms, loggers, meters } = useSiteDevices(siteId);
  const [meterIds, setMeterIds] = useState([]);

  useEffect(() => {
    setMeterIds(sortBy(meters, 'parent_index').map((meter) => meter.meter_id));
  }, [meters]);

  const active = (() => {
    let active = true;
    loggers.forEach((logger) => {
      if (!logger.active) active = false;
    });
    return active;
  })();

  const handleSiteClick = () => {
    dispatch(
      navigate({
        page: 'site',
        id: site.site_id,
      })
    );
  };

  const handleIconClick = () => {
    dispatch(
      openDialog({
        type: 'site',
        mode: 'edit',
        id: site.site_id,
      })
    );
  };

  const handleClick = (view) => {
    dispatch(
      navigate({
        page: 'site',
        id: siteId,
        tab: 'analysis',
        view: view,
      })
    );
  };

  const activeAlarms = filter(alarms, { status: true });
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        backgroundImage: (theme) => theme.vars.overlays[8],
        boxShadow: (theme) => theme.shadows[4],
        borderRadius: 1,
        px: 1,
        pt: 1,
        pb: 0.5,
        width: '100%',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <AlarmPreviewLink
          alarms={activeAlarms}
          deviceName={site.name}
          handleClick={() => handleSiteClick()}
        />
        <IconButton onClick={() => handleIconClick()} sx={{ width: 40 }}>
          <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
        </IconButton>
      </Box>
      {active ? (
        <Grid container padding={0}>
          <Grid size={{ xs: 12, md: 8 }}>
            <SiteProfileChart
              orgId={site.org_id}
              siteId={site.site_id}
              meterIds={meterIds}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <CustomLink handleClick={() => handleClick('performance')}>
              {(expectationType === 'modeled'
                ? 'Modeled'
                : 'Weather Corrected') + ' Performance'}
            </CustomLink>
            <SitePerformance
              siteId={site.site_id}
              expectationType={expectationType}
            />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ my: 4, width: '100%' }}>
          <Typography align='center' color='textSecondary'>
            Data Logger is inactive
          </Typography>
        </Box>
      )}
    </Box>
  );
}

SiteCard.propTypes = {
  siteId: PropTypes.string.isRequired,
  expectationType: PropTypes.string.isRequired,
};

export default SiteCard;
