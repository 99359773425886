import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import ROUTES from '../../../constants/routes';
import { navigate } from '../../../store/pages';
import useWebsocket from '../../../store/hooks/useWebsocket';
import Dialogs from './Dialogs';
import CustomAppBar from './CustomAppBar';
import CustomDrawer from './CustomDrawer';
import LoadingBar from './LoadingBar';

function Layout() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const location = useLocation();
  const dispatch = useDispatch();
  const toolbarRef = useRef();
  useWebsocket();

  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === '/app') {
      dispatch(push(ROUTES.AUTH.PORTFOLIO));
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (location.search.includes('?resource=')) {
      const resource_id = location.search.split('=')[1];

      if (resource_id) {
        const type = resource_id.split(':')[0];
        dispatch(navigate({ page: type, id: resource_id }));
      }
    }
  }, [location, dispatch]);

  // close mobile drawer if window exceeds breakpoint
  useEffect(() => {
    if (!isMobile) setMobileOpen(false);
  }, [isMobile]);

  const marginTop = toolbarRef?.current?.offsetHeight || 64;
  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100vw' }}>
      <CssBaseline />
      <LoadingBar />
      <CustomAppBar ref={toolbarRef} setMobileOpen={setMobileOpen} />
      <CustomDrawer mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <Box component='main' sx={{ flexGrow: 1, mt: marginTop + 'px' }}>
        <Outlet context={{ toolbarRef }} />
      </Box>
      <Dialogs />
    </Box>
  );
}

export default Layout;
