import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import each from 'lodash/each';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import map from 'lodash/map';
import range from 'lodash/range';

import { alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { navigate } from '../../../../store/pages';
import useSite from '../../../../store/hooks/useSite';
import {
  addEstimatedProductionToChartData,
  clipProductionData,
} from '../../../../helpers/chart-data';
import ComponentLoader from '../../../Loaders/ComponentLoader';
import ChartTooltip from './Tooltip';

function SiteProfileChart({ orgId, siteId, meterIds }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { loading: meterLoading } = useSelector((state) => state.meters);
  const { data: timeseries, loading: timeseriesLoading } = useSelector(
    (state) => state.timeseries
  );
  const loading = meterLoading || timeseriesLoading;

  const site = useSite(siteId);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    const zone = site?.timezone?.zone;
    if (zone) {
      const _start = dayjs().tz(zone).startOf('day').subtract(1, 'day');
      setStart(_start);
      setEnd(_start.add(2, 'day'));
    }
  }, [site?.timezone?.zone]);

  useEffect(() => {
    if (!start || !end || meterIds.length === 0) return;
    let _data = map(range(start.unix(), end.unix(), 900), (timestamp) => ({
      timestamp,
    }));
    const recentTimeseries = filter(timeseries, (series) => {
      return (
        meterIds.includes(series?.device_id) && series?.data_type === 'recent'
      );
    });
    each(recentTimeseries, (item) => {
      each(item.data, (datapoint) => {
        let idx = findIndex(_data, { timestamp: datapoint.timestamp });
        if (idx >= 0) {
          _data[idx] = {
            ..._data[idx],
            [item.device_id]: clipProductionData(datapoint.value, orgId),
          };
        }
      });
    });
    const estimatedTimeseries = filter(timeseries, (series) => {
      return (
        meterIds.includes(series?.device_id) &&
        series?.data_type === 'estimated'
      );
    });
    setData(
      addEstimatedProductionToChartData(
        _data,
        estimatedTimeseries,
        site.timezone.zone
      )
    );
  }, [start, end, orgId, meterIds, timeseries, site?.timezone?.zone]);

  const handleClick = (view) => {
    dispatch(
      navigate({
        page: 'site',
        id: siteId,
        tab: 'analysis',
        view: view,
      })
    );
  };

  const height = isMobile ? 150 : 175;
  if (!start || !end || loading)
    return (
      <div style={{ height }}>
        <ComponentLoader height={50} width={50} />
      </div>
    );

  let startReference = start.add(6, 'hour');
  let endReference = start.add(30, 'hour');
  return (
    <ResponsiveContainer width='100%' height={height}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}>
        <YAxis hide domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]} />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[start.unix(), end.unix()]}
          hide
        />
        <ReferenceLine
          x={startReference.unix()}
          stroke='transparent'
          label={
            <Label position='insideTopLeft'>
              {startReference.format('MMM Do')}
            </Label>
          }
        />
        <ReferenceLine
          x={endReference.unix()}
          stroke='transparent'
          label={
            <Label position='insideTopLeft'>
              {endReference.format('MMM Do')}
            </Label>
          }
        />
        <Tooltip content={<ChartTooltip timezone={site.timezone} />} />
        {map(meterIds, (meterId, idx) => {
          return (
            <Area
              stackId='areas'
              key={`area-${meterId}`}
              type='monotone'
              dataKey={meterId}
              fill={alpha(
                theme.veregy_colors.blue,
                1 - idx * (1 / Math.max(meterIds.length, 4))
              )}
            />
          );
        })}
        {map(meterIds, (meterId, idx) => {
          return (
            <Bar
              stackId='estimated'
              key={`estimated-${meterId}`}
              dataKey={`${meterId}:estimated`}
              fill={alpha(theme.veregy_colors.lightgrey, 1 - idx * 0.2)}
              stroke='transparent'
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

SiteProfileChart.propTypes = { siteId: PropTypes.string };
export default SiteProfileChart;
