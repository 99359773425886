import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import Grid from '@mui/material/Grid2';

import SiteCard from './SiteCard';

function SitesGrid(props) {
  const { sites, expectationType, loading } = props;

  if (!loading && sites.length === 0) return null;

  return map(sortBy(sites, 'name'), (site) => {
    return (
      <Grid
        key={site.site_id}
        size={{ xs: 12 }}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <SiteCard siteId={site.site_id} expectationType={expectationType} />
      </Grid>
    );
  });
}

SitesGrid.propTypes = {
  organization: PropTypes.object,
  sites: PropTypes.array,
  expectationType: PropTypes.string,
  loading: PropTypes.bool,
};

export default SitesGrid;
