import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import WidgetLoader from './Loader';
function ResourceCount(props) {
  const { children, loading } = props;
  return (
    <Card
      raised
      sx={{
        height: '100%',
        borderBottom: (theme) => `10px solid ${theme.veregy_colors.grey}`,
        px: 1,
        pt: 5,
        pb: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Stack direction='row' justifyContent='space-evenly'>
        {children}
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography align='left' variant='h5'>
          Resources
        </Typography>
        {loading && <WidgetLoader />}
      </Box>
    </Card>
  );
}

ResourceCount.propTypes = {
  children: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ResourceCount;
