import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import find from 'lodash/find';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { closeDialog } from '../../store/dialogs';
import { showLoading, hideLoading } from '../../store/app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { toastr } from '../CustomToast';
import BaseDialog from './BaseDialog';

function GenerateReportDialog() {
  const dispatch = useDispatch();

  const { reportId } = useSelector((state) => state.dialogs.generateReport);
  const reports = useSelector((state) => state.reports.data);

  const [report, setReport] = useState({});
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    if (reportId) {
      setReport(find(reports, { report_id: reportId }));
    }
  }, [reportId, reports]);

  const handleClose = () => {
    dispatch(closeDialog('generateReport'));
    setReport({});
    setDate(dayjs());
  };

  const handleConfirm = () => {
    dispatch(showLoading());
    new WebAPIClient()
      .POST('/resource/report_files', {
        org_id: report.org_id,
        report_id: report.report_id,
        period: report.period,
        year: date.year(),
        month: date.month() + 1,
      })
      .then(() => {
        toastr.success({
          title: 'Report Generation In Progress',
          message:
            'You should receive an email shortly with the report attached',
        });
        handleClose();
      })
      .catch((err) => {
        errorResponseToastr(err);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  return (
    <BaseDialog
      id={reportId}
      title='Generate Report'
      handleSubmit={handleConfirm}
      handleClose={handleClose}>
      {report?.period === 'month' && (
        <DatePicker
          disableFuture
          views={['year', 'month']}
          label='Select Month'
          value={date}
          onChange={(newValue) => {
            setDate(newValue);
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: 'standard',
              inputProps: {
                readOnly: true,
              },
            },
          }}
        />
      )}
    </BaseDialog>
  );
}

export default GenerateReportDialog;
