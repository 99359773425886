import React from 'react';

import Grid from '@mui/material/Grid2';

import ROLES from '../constants/roles';
import useVerifyOrgRole from '../store/hooks/useVerifyOrgRole';
import AlarmsConfigurationTable from './table/tables/AlarmsConfigurationTable';

export default function AlarmConfigurationPage(props) {
  const { resource } = props;
  const isOrgEditor = useVerifyOrgRole(resource?.org_id, ROLES.EDITOR.value);
  const typeSelect = !['inverter', 'sensor'].includes(resource?.type_);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      sx={{ maxWidth: '100%', px: 1, pt: 1 }}>
      <AlarmsConfigurationTable
        resource={resource}
        typeSelect={typeSelect}
        isOrgEditor={isOrgEditor}
      />
    </Grid>
  );
}
