import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import useTheme from '@mui/material/styles/useTheme';

import {
  Area,
  Bar,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Loader from '../../../Loaders/ComponentLoader';

import { chartColor } from '../../../../styles/muiTheme';
import { generateTicks } from '../../../../helpers/chart-data';
import { formatTimestamp } from '../../../../helpers/date';
import ChartTooltip from './Tooltip';

function WeatherStationChart({
  chartData,
  sensors,
  meters,
  range,
  timezone,
  loading,
}) {
  const theme = useTheme();

  if (loading)
    return (
      <div style={{ height: '450px' }}>
        <Loader height={150} width={150} />
      </div>
    );

  if (isEmpty(chartData))
    return (
      <div
        style={{
          height: '450px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    );

  const ticks = generateTicks(range);
  return (
    <ResponsiveContainer key='analysis-weather-chart' width='100%' height={450}>
      <ComposedChart
        width='100%'
        height={450}
        data={chartData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}>
        <YAxis yAxisId='kw' hide domain={[0, 'auto']} allowDataOverflow />
        <YAxis
          mirror
          yAxisId='temp'
          orientation='right'
          domain={[
            (dataMin) => Math.min(0, dataMin),
            (dataMax) => Math.ceil(dataMax / 10) * 10,
          ]}
          tickFormatter={(val, _axis) => {
            if (val === 0) return '';
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' °C';
          }}
        />
        <YAxis
          mirror
          yAxisId='radiation'
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
          width={100}
          tickFormatter={(val, _axis) => {
            if (val === 0) return '';
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' W/m^2';
          }}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          tickMargin={10}
          domain={[range.start.unix(), range.end.unix()]}
          ticks={ticks}
          tickFormatter={(unixTime) => {
            let format = 'MMM Do h:mm A';
            if (range.end.unix() - range.start.unix() > 90000) {
              format = 'MMM Do';
            }
            return formatTimestamp(unixTime, timezone, format);
          }}
        />
        <Tooltip
          content={
            <ChartTooltip
              timezone={timezone}
              sensors={sensors}
              meters={meters}
            />
          }
        />
        {map(meters, (meter, idx) => {
          return (
            <Bar
              yAxisId='kw'
              stackId='a'
              key={`bar-${meter.meter_id}`}
              type='monotone'
              dataKey={meter.meter_id}
              fill={chartColor(idx)}
              stroke='transparent'
            />
          );
        })}
        {map(sensors, (sensor) => (
          <Area
            yAxisId='radiation'
            key={`radiation-area-${sensor.sensor_id}`}
            dataKey={`${sensor.sensor_id}:Radiation`}
            stroke={theme.veregy_colors.darkYellow}
            fill={theme.veregy_colors.darkYellow}
            fillOpacity={0.7}
          />
        ))}
        {map(sensors, (sensor) => (
          <Line
            yAxisId='temp'
            dot={false}
            key={`temp-line-${sensor.sensor_id}`}
            dataKey={`${sensor.sensor_id}:PanelTemp`}
            stroke={theme.veregy_colors.orange}
            connectNulls
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

WeatherStationChart.propTypes = {
  chartData: PropTypes.array,
  sensors: PropTypes.array,
  meters: PropTypes.array,
  range: PropTypes.object,
  timezone: PropTypes.object,
  loading: PropTypes.bool,
};

export default WeatherStationChart;
