import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';

import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';

import { getImages, getPresignedUrl } from '../../../store/images/_images';
import useLicense, { KIOSK_LICENSE } from '../../../store/hooks/useLicense';
import CaseStudy from './CaseStudy';
import Images from './Images';
import Slides from './Slides';
import Settings from './Settings';
import ClipboardField from '../../ClipboardField';

const LicenseInactiveAlert = (props) => {
  const { status, orgId } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 250);

    return () => clearTimeout(timer);
  }, []);

  if (!show || orgId || status) return null;
  return (
    <Grid size={{ xs: 12 }}>
      <Alert severity='error'>
        This Organization's Kiosk License is inactive. Contact an administrator
        for further assistance.
      </Alert>
    </Grid>
  );
};

function Kiosk(props) {
  const { resourceId } = props;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const organizations = useSelector((state) => state.organizations.data);
  const sites = useSelector((state) => state.sites.data);

  const [resource, setResource] = useState({});
  const license = useLicense(resource?.org_id, KIOSK_LICENSE);

  useEffect(() => {
    if (resourceId.startsWith('org')) {
      setResource(find(organizations, { org_id: resourceId }));
    } else if (resourceId.startsWith('site')) {
      setResource(find(sites, { site_id: resourceId }));
    }
  }, [resourceId, organizations, sites]);

  useEffect(() => {
    const fetchImages = async () => {
      const { payload: allImages } = await dispatch(getImages(resourceId));
      const resourceImages = get(allImages, resourceId);
      let _logoFile = find(resourceImages, (image) =>
        image.startsWith('logo.')
      );
      let _caseStudyFile = find(resourceImages, (image) =>
        image.startsWith('case-study.')
      );

      if (_logoFile) {
        await dispatch(getPresignedUrl({ resourceId, key: _logoFile }));
      }

      if (_caseStudyFile) {
        await dispatch(getPresignedUrl({ resourceId, key: _caseStudyFile }));
      }
    };

    if (resourceId) {
      fetchImages();
    }
  }, [resourceId, dispatch]);

  const kioskType = resourceId.split(':')[0];
  const kioskURL =
    process.env.REACT_APP_WEB_URL +
    'kiosk?' +
    kioskType +
    '=' +
    resource?.kiosk_code;
  return (
    <>
      <LicenseInactiveAlert
        orgId={resource?.org_id}
        status={license.effectiveStatus}
      />
      <Grid
        size={{ xs: 12 }}
        sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Card raised sx={{ width: '100%', p: 1, pb: 0.5 }}>
          <ClipboardField
            id='kiosk-url'
            label='Kiosk URL'
            value={kioskURL}
            clipboardPosition='start'
          />
        </Card>
      </Grid>
      {!isMobile && <Settings resource={resource} resourceId={resourceId} />}
      {!isMobile && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <Slides resource={resource} />
        </Grid>
      )}
      <Grid
        size={{ xs: 12, md: 6 }}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <CaseStudy resourceId={resourceId} resource={resource} />
      </Grid>
      <Grid
        size={{ xs: 12, md: 6 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
        }}>
        <Images resourceId={resourceId} resource={resource} />
      </Grid>
    </>
  );
}

export default Kiosk;
