import React from 'react';
import ReactLoader from 'react-loading';

import useTheme from '@mui/material/styles/useTheme';
import styled from '@mui/material/styles/styled';

const ReactLoaderStyled = styled(ReactLoader)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export default function WidgetLoader(props) {
  const { delay = 500 } = props;
  const theme = useTheme();

  return (
    <ReactLoaderStyled
      type='bars'
      color={theme.veregy_colors.grey}
      height={25}
      width={25}
      delay={delay}
    />
  );
}
