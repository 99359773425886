import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import ROLES from '../../../constants/roles';
import { putOrganization } from '../../../store/organizations/_organizations';
import { putSite } from '../../../store/sites';
import {
  deleteImage,
  getImages,
  getPresignedUrl,
  postImage,
} from '../../../store/images/_images';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import ColorPreview from '../../ColorPreview';
import ToolbarPreview from './ToolbarPreview';

function Settings(props) {
  const { resource, resourceId } = props;
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const isOrgEditor = useVerifyOrgRole(resource?.org_id, ROLES.EDITOR.value);
  const images = useSelector((state) => get(state.images, resourceId, []));
  const storeUrls = useSelector((state) => state.images.presignedUrls);

  const [logoFilename, setLogoFilename] = useState('');
  const [url, setUrl] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imageBlobUrl, setImageBlobUrl] = useState(null);
  const [imageFilename, setImageFilename] = useState('');
  const [height, setHeight] = useState('');
  const [padding, setPadding] = useState('');
  const [primary, setPrimary] = useState('');
  const [secondary, setSecondary] = useState('');
  const [text, setText] = useState('');
  const [energyRate, setEnergyRate] = useState('');

  const primarySetting = get(resource, 'theme.primary', '');
  const secondarySetting = get(resource, 'theme.secondary', '');
  const textSetting = get(resource, 'theme.text', '');
  const energyRateSetting = get(resource, 'theme.energy_rate', '');
  const logoHeightSetting = get(resource, 'theme.logo.height', 75);
  const logoPaddingSetting = get(resource, 'theme.logo.padding', 8);

  useEffect(() => {
    let _logoFile = find(images, (image) => image.startsWith('logo.'));
    if (_logoFile) {
      setLogoFilename(_logoFile);
    } else {
      setLogoFilename('');
    }
  }, [images, resourceId, dispatch]);

  useEffect(() => {
    let resourceUrls = get(storeUrls, `${resourceId}`);
    if (resourceUrls) {
      let _url = resourceUrls[logoFilename];
      setUrl(_url);
    }
  }, [storeUrls, resourceId, logoFilename]);

  useEffect(() => {
    setHeight(get(resource, 'theme.logo.height', 75));
    setPadding(get(resource, 'theme.logo.padding', 8));
  }, [resource]);

  useEffect(() => {
    setEnergyRate(get(resource, 'theme.energy_rate', ''));
    const _primary = get(resource, 'theme.primary', '');
    const _secondary = get(resource, 'theme.secondary', '');
    const _text = get(resource, 'theme.text', '');

    if (_primary) setPrimary(_primary);
    if (_secondary) setSecondary(_secondary);
    if (_text) setText(_text);
  }, [resource]);

  const handleDelete = async (e) => {
    e.preventDefault();

    if (imageBlobUrl) {
      setImageFile(null);
      setImageFilename('');
      setImageBlobUrl(null);
    } else if (url) {
      await dispatch(deleteImage({ resourceId, filename: logoFilename }));
      dispatch(getImages(resourceId));
      setUrl('');
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    let _imageFile = null;
    if (e.dataTransfer) {
      _imageFile = e.dataTransfer.files[0];
    } else if (e.target.files) {
      _imageFile = e.target.files[0];
    }

    setImageFile(_imageFile);
    setImageFilename(_imageFile.name);
    setImageBlobUrl(URL.createObjectURL(_imageFile));
  };

  const handleSaveLogo = async () => {
    const lastDotIndex = imageFilename.lastIndexOf('.');
    const ext = imageFilename.slice(lastDotIndex);

    if (logoFilename) {
      await dispatch(deleteImage({ resourceId, filename: logoFilename }));
    }

    await dispatch(
      postImage({
        resource_id: resourceId,
        image: imageFile,
        filename: 'logo' + ext,
      })
    );
    await dispatch(getImages(resourceId));
    setImageFile(null);
    setImageFilename('');
    setImageBlobUrl(null);
    dispatch(getPresignedUrl({ resourceId, key: logoFilename }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (imageFile) {
      handleSaveLogo();
    }

    const settings = {
      theme: {
        primary,
        secondary,
        text,
        energy_rate: energyRate,
        logo: {
          height: Number(height),
          padding: Number(padding),
        },
      },
    };
    if (resource.type_ === 'organization') {
      dispatch(
        putOrganization({
          org_id: resource.org_id,
          ...settings,
        })
      );
    } else if (resource.type_ === 'site') {
      dispatch(
        putSite({
          org_id: resource.org_id,
          site_id: resource.site_id,
          ...settings,
        })
      );
    }
  };

  const changeDetected = (() => {
    return (
      primary !== primarySetting ||
      secondary !== secondarySetting ||
      text !== textSetting ||
      imageBlobUrl ||
      height !== logoHeightSetting ||
      padding !== logoPaddingSetting ||
      energyRate !== energyRateSetting
    );
  })();

  return (
    <>
      <Grid size={{ xs: 12 }}>
        <ToolbarPreview
          resource={resource}
          logoFilename={logoFilename}
          url={url}
          imageBlobUrl={imageBlobUrl}
          imageFilename={imageFilename}
          height={height}
          padding={padding}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Card raised sx={{ p: 1, px: 2 }}>
          <CardHeader
            sx={{ p: 0, pb: '8px !important' }}
            title='Settings'
            titleTypographyProps={{ variant: 'h6' }}
            action={
              <>
                {(url || imageBlobUrl) && (
                  <IconButton disabled={!isOrgEditor} onClick={handleDelete}>
                    <FontAwesomeIcon
                      icon={['fal', 'trash']}
                      style={{ height: 23, width: 23 }}
                    />
                  </IconButton>
                )}

                <Tooltip placement='top' title='upload'>
                  <IconButton component='label' disabled={!isOrgEditor}>
                    <FontAwesomeIcon
                      icon={['fal', 'upload']}
                      style={{ height: 23, width: 23 }}
                    />
                    <input
                      hidden
                      accept='image/*'
                      type='file'
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                    />
                  </IconButton>
                </Tooltip>
                <IconButton
                  sx={{ mr: 1 }}
                  disabled={!isOrgEditor || !changeDetected}
                  onClick={handleSave}>
                  <FontAwesomeIcon
                    icon={['fal', 'save']}
                    style={{ height: 23, width: 23 }}
                  />
                </IconButton>
              </>
            }
          />
          <Grid container spacing={4}>
            <Grid container size={{ xs: 12, md: 6 }} spacing={1}>
              <Grid size={{ xs: 12 }}>
                <TextField
                  id='primary'
                  label='Primary Color'
                  placeholder='#195e88 (default)'
                  value={primary || ''}
                  fullWidth
                  variant='standard'
                  onChange={(e) => setPrimary(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSave(e);
                    }
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <ColorPreview color={primary || '#195e88'} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <TextField
                  id='secondary'
                  label='Secondary Color'
                  placeholder='#73b043 (default)'
                  value={secondary || ''}
                  fullWidth
                  variant='standard'
                  onChange={(e) => setSecondary(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSave(e);
                    }
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <ColorPreview color={secondary || '#73b043'} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <TextField
                  id='text'
                  label='Text Color'
                  placeholder='auto'
                  value={text || ''}
                  fullWidth
                  variant='standard'
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSave(e);
                    }
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <ColorPreview color={text} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container size={{ xs: 12, md: 6 }} spacing={1}>
              <Grid size={{ xs: 12 }}>
                <TextField
                  id='energy_rate'
                  type='number'
                  fullWidth
                  variant='standard'
                  label='Energy Rate'
                  value={energyRate || ''}
                  onChange={(e) => setEnergyRate(e.target.value)}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position='end'>$/kWh</InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <TextField
                  fullWidth
                  variant='standard'
                  disabled={!isOrgEditor}
                  label='Logo height (px)'
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSave(e);
                    }
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <TextField
                  fullWidth
                  variant='standard'
                  disabled={!isOrgEditor}
                  label='Logo padding (px)'
                  value={padding}
                  onChange={(e) => setPadding(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSave(e);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

export default Settings;
