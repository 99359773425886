import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import get from 'lodash/get';
import map from 'lodash/map';

import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Loader from '../../../Loaders/ComponentLoader';

import { generateTicks } from '../../../../helpers/chart-data';
import { formatTimestamp } from '../../../../helpers/date';
import { displaykW } from '../../../../helpers/display-energy';
import ChartTooltip from './Tooltip';

const AREAS = [{ key: 'radiation', name: 'Irradiance', yAxisId: 'right' }];

const LINES = [
  {
    key: 'production',
    name: 'Production (actual)',
    yAxisId: 'left',
  },
  {
    key: 'powerEstimate',
    name: 'Production (estimated)',
    yAxisId: 'left',
  },
  {
    key: 'temp',
    name: 'Panel Temperature',
    yAxisId: 'left',
  },
];

function EstimatedChart(props) {
  const { loading, range, chartData, totals, timezone } = props;
  const theme = useTheme();

  const getColor = (key) => {
    switch (key) {
      case 'production':
        return theme.veregy_colors.blue;

      case 'powerEstimate':
        return theme.veregy_colors.green;

      case 'radiation':
        return theme.veregy_colors.darkYellow;

      case 'temp':
        return theme.veregy_colors.orange;

      default:
        break;
    }
    return theme.veregy_colors.blue;
  };

  const ticks = generateTicks(range);
  return (
    <>
      <Grid size={{ xs: 12 }}>
        <Card raised sx={{ px: 1, py: 0.5 }}>
          {loading || (!range?.start && !range?.end) ? (
            <div style={{ height: '400px' }}>
              <Loader height={150} width={150} />
            </div>
          ) : (
            <ResponsiveContainer width='100%' height={400}>
              <ComposedChart
                width='100%'
                height={400}
                data={chartData}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 10,
                }}>
                <YAxis
                  mirror
                  yAxisId='left'
                  domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
                  tickFormatter={(val, _axis) => {
                    if (val === 0) return '';
                    return (
                      numeral(val.toPrecision(4)).format('0,0.[000]') + ' kW'
                    );
                  }}
                />
                <YAxis
                  mirror
                  yAxisId='right'
                  width={100}
                  orientation='right'
                  domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
                  tickFormatter={(val, _axis) => {
                    if (val === 0) return '';
                    return (
                      numeral(val.toPrecision(4)).format('0,0.[000]') + ' W/m^2'
                    );
                  }}
                />
                <XAxis
                  type='number'
                  dataKey='timestamp'
                  domain={[range.start.unix(), range.end.unix()]}
                  ticks={ticks}
                  tickFormatter={(unixTime) => {
                    let format = 'MMM Do h:mm A';
                    if (range.end.unix() - range.start.unix() > 90000) {
                      format = 'MMM Do';
                    }
                    return formatTimestamp(unixTime, timezone, format);
                  }}
                  tickMargin={10}
                />

                <Tooltip content={<ChartTooltip timezone={timezone} />} />

                {map(AREAS, (area) => {
                  return (
                    <Area
                      yAxisId={get(area, 'yAxisId')}
                      key={get(area, 'key')}
                      name={get(area, 'name')}
                      type='monotone'
                      dataKey={get(area, 'key')}
                      stroke={getColor(get(area, 'key'))}
                      fill={getColor(get(area, 'key'))}
                      fillOpacity={0.7}
                      unit={'W/m^2'}
                    />
                  );
                })}

                {map(LINES, (line) => {
                  return (
                    <Line
                      key={get(line, 'key')}
                      yAxisId={get(line, 'yAxisId')}
                      name={get(line, 'name')}
                      type='monotone'
                      dataKey={get(line, 'key')}
                      stroke={getColor(get(line, 'key'))}
                      strokeWidth={2}
                      unit={'kW'}
                      dot={false}
                    />
                  );
                })}
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </Card>
      </Grid>
      <Grid
        size={{ xs: 12 }}
        sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <List sx={{ width: '100%', maxWidth: 350 }}>
          <ListItem>
            <ListItemText secondary='Actual Production: ' />
            <ListItemText
              primary={displaykW(totals.actual, true)}
              slotProps={{
                primary: {
                  fontWeight: 'bold',
                  align: 'right',
                  sx: { color: (theme) => theme.veregy_colors.blue },
                },
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText secondary='Estimated Production: ' />
            <ListItemText
              primary={displaykW(totals.estimated, true)}
              slotProps={{
                primary: {
                  fontWeight: 'bold',
                  align: 'right',
                  sx: { color: (theme) => theme.veregy_colors.orange },
                },
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText secondary='Total Irradiance: ' />
            <ListItemText
              primary={
                numeral(totals.irradiance.toPrecision(4)).format('0,0.[000]') +
                ' Wh/m^2'
              }
              slotProps={{
                primary: {
                  fontWeight: 'bold',
                  align: 'right',
                },
              }}
            />
          </ListItem>
        </List>
      </Grid>
    </>
  );
}

EstimatedChart.propTypes = {
  loading: PropTypes.bool,
  range: PropTypes.object,
  chartData: PropTypes.array,
  totals: PropTypes.object,
  timezone: PropTypes.object,
};

export default EstimatedChart;
