import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { generateCsvString } from '../../../../helpers/csv-data';
import SelectRange from '../../selectors/SelectRange';
import SelectTimezone from '../../selectors/SelectTimezone';
import SelectNumber from '../../selectors/SelectNumber';
import GenerateCSVButton from '../../buttons/GenerateCSVButton';
import ChartHeader from '../ChartHeader';

const prepareCsvData = (data) => {
  let headers = [
    'timestamp',
    'production',
    'radiation',
    'temp',
    'powerEstimate',
  ];

  return generateCsvString(
    headers,
    map(data, (values) => {
      return {
        ...values,
        timestamp: dayjs(values.timestamp).format(),
      };
    })
  );
};

function Toolbar(props) {
  const {
    site,
    range,
    selectRange,
    timezone,
    selectTimezone,
    inverterEfficiency,
    setInverterEfficiency,
    systemLosses,
    setSystemLosses,
    tempCoefficient,
    setTempCoefficient,
    chartData,
    fetchRawData,
  } = props;

  return (
    <ChartHeader>
      <SelectRange range={range} setRange={selectRange} timezone={timezone} />
      <SelectTimezone
        selectedTimezone={timezone}
        setSelectedTimezone={selectTimezone}
      />
      <SelectNumber
        value={inverterEfficiency}
        setValue={setInverterEfficiency}
        label='Inverter Efficiency'
      />
      <SelectNumber
        value={systemLosses}
        setValue={setSystemLosses}
        label='System Losses'
      />
      <SelectNumber
        value={tempCoefficient}
        setValue={setTempCoefficient}
        label='Temp Coefficient'
      />
      <GenerateCSVButton
        generateCsvString={() => prepareCsvData(chartData)}
        filename={`${site.name} Estimated Production (${dayjs().format(
          'ddd MMM DD YYYY'
        )})`}
      />
      <Tooltip title='Fetch Data' placement='top'>
        <IconButton onClick={() => fetchRawData()}>
          <FontAwesomeIcon icon={['fal', 'cloud-download']} />
        </IconButton>
      </Tooltip>
    </ChartHeader>
  );
}

Toolbar.propTypes = {
  site: PropTypes.object,
  range: PropTypes.object,
  selectRange: PropTypes.func,
  timezone: PropTypes.object,
  selectTimezone: PropTypes.func,
  inverterEfficiency: PropTypes.number,
  setInverterEfficiency: PropTypes.func,
  systemLosses: PropTypes.number,
  setSystemLosses: PropTypes.func,
  tempCoefficient: PropTypes.number,
  setTempCoefficient: PropTypes.func,
  chartData: PropTypes.array,
  fetchRawData: PropTypes.func,
};

export default Toolbar;
