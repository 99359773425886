import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import { navigate } from '../../../store/pages';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import { useEffect } from 'react';
import SelectPeriod from '../../charts/selectors/SelectPeriod';
import dayjs from 'dayjs';
import SelectOptionalContent from '../../charts/selectors/SelectOptionalContent';

const defaultResource = {
  name: '',
  type: 'Resource',
  org_id: '',
  optional: [],
  equipment: '',
  contact: '',
};

function Form(props) {
  const { mode, template, report, setReport, handleClose } = props;
  const dispatch = useDispatch();
  const { resource: storeResource } = useSelector(
    (state) => state.dialogs.report
  );
  const organizations = useSelector((state) => state.organizations.data);
  const sites = useSelector((state) => state.sites.data);

  const [resource, setResource] = useState(defaultResource);

  useEffect(() => {
    let _resource = defaultResource;
    if (report?.device_id.startsWith('org')) {
      _resource = find(organizations, { org_id: report?.device_id });
    } else if (report?.device_id.startsWith('site')) {
      _resource = find(sites, { site_id: report?.device_id });
    }

    if (storeResource) {
      _resource = storeResource;
    }
    setResource(_resource);
  }, [report.device_id, organizations, sites, storeResource]);

  const setPeriod = (period) => {
    let _day = report.day;
    if (period === 'week' && report.day > 7) {
      _day = 7;
    }
    setReport({ ...report, day: _day, period });
  };

  const setDay = (e) => {
    setReport({ ...report, day: e.target.value });
  };

  const handleChange = (e) => {
    setReport({ ...report, [e.target.id]: e.target.value });
  };

  const setOptionalContent = (contents) => {
    setReport({ ...report, optional: contents });
  };

  const findNext = () => {
    const currentDate = dayjs();
    let nextDate = currentDate.add(1, 'day');

    if (report.period === 'week') {
      const currentDayOfWeek = currentDate.day();
      let daysToAdd = Number(report.day) - currentDayOfWeek;
      if (daysToAdd <= 0) {
        daysToAdd = 7 - currentDayOfWeek + Number(report.day);
      }

      nextDate = currentDate.add(daysToAdd, 'day');
    } else if (report.period === 'month') {
      const currentDayOfMonth = currentDate.date();
      const daysInThisMonth = currentDate.daysInMonth();
      let daysToAdd = Number(report.day) - currentDayOfMonth;
      if (daysToAdd <= 0) {
        daysToAdd = daysInThisMonth - currentDayOfMonth + Number(report.day);
      }

      nextDate = currentDate.add(daysToAdd, 'day');
    } else if (report.period === 'year') {
      if (currentDate.month() !== 1) {
        nextDate = currentDate.add(1, 'year').month(1).date(report.day);
      } else {
        const currentDayOfMonth = currentDate.date();
        const daysInThisMonth = currentDate.daysInMonth();
        let daysToAdd = Number(report.day) - currentDayOfMonth;
        if (daysToAdd <= 0) {
          daysToAdd = daysInThisMonth - currentDayOfMonth + Number(report.day);
        }

        nextDate = currentDate.add(daysToAdd, 'day');
      }
    }

    return nextDate.format('MMMM Do, YYYY');
  };

  return (
    <List disablePadding>
      {/* NAME */}
      <TextFieldListItem
        value={report?.name || template?.title || ''}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      {/* RESOURCE */}
      <ListItem disableGutters>
        <Link
          underline='hover'
          onClick={
            mode === 'edit'
              ? () => {
                  dispatch(
                    navigate({
                      page: resource.type_,
                      id: resource.org_id,
                    })
                  );
                  handleClose();
                }
              : null
          }>
          {resource.name}
        </Link>
      </ListItem>
      {/* REPORT ID */}
      {(mode === 'edit' || mode === 'view') && (
        <TextFieldListItem
          id='report_id'
          label='Report ID'
          value={report.report_id}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
        />
      )}
      {/* PERIOD / DAY */}
      <ListItem
        sx={{ display: 'flex', justifyContent: 'space-between', pl: 0, pr: 0 }}>
        <SelectPeriod
          disabled={mode === 'view'}
          selectedPeriod={report.period}
          setSelectedPeriod={setPeriod}
        />
        {report.period !== 'day' && (
          <TextField
            disabled={mode === 'view'}
            label='Day'
            type='number'
            sx={{
              width: '45%',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
              },
              '& .MuiInputLabel-root.Mui-disabled': {
                WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)',
              },
            }}
            variant='standard'
            onChange={setDay}
            value={report.day}
            slotProps={{
              input: {
                disableUnderline: mode === 'view',
                inputProps: {
                  min: '1',
                  max: report.period === 'week' ? '7' : '31',
                  step: '1',
                },
              },
            }}
          />
        )}
      </ListItem>
      {/* NEXT */}
      <ListItem disableGutters>
        <TextField
          id='next'
          label='Next'
          value={findNext()}
          fullWidth
          variant='standard'
          slotProps={{
            input: {
              readOnly: true,
              disableUnderline: true,
            },
          }}
        />
      </ListItem>
      {/* OPTIONAL */}
      <ListItem disableGutters>
        <SelectOptionalContent
          disabled={mode === 'view'}
          selectedContents={report?.optional}
          setSelectedContents={setOptionalContent}
          contentOptions={template?.optional}
        />
      </ListItem>
      {/* EQUIPMENT */}
      <TextFieldListItem
        id='equipment'
        label='Equipment'
        value={report.equipment || ''}
        multiline
        onChange={handleChange}
      />
      {/* CONTACT */}
      <TextFieldListItem
        id='contact'
        label='Contact'
        value={report.contact || ''}
        multiline
        onChange={handleChange}
      />
    </List>
  );
}

Form.propTypes = {
  site: PropTypes.object,
  logger: PropTypes.object,
  report: PropTypes.object,
  setReport: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Form;
