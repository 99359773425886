import React from 'react';
import TermsContent from './TermsContent';
import PrivacyContent from './PrivacyContent';

import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

export const Title = ({ children }) => (
  <ListItem>
    <Typography variant='h4'>{children}</Typography>
  </ListItem>
);

export const SubHeading = ({ children }) => (
  <ListItem>
    <Typography variant='h5' component='h3'>
      {children}
    </Typography>
  </ListItem>
);

export const Paragraph = ({ children }) => (
  <ListItem>
    <Typography variant='body1'>{children}</Typography>
  </ListItem>
);

const TermsAndPrivacyContainer = ({ children }) => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      sx={{ pt: 2, mb: 2 }}>
      <Grid size={{ xs: 12, lg: 8, xl: 6 }}>
        <div>{children}</div>
      </Grid>
    </Grid>
  );
};

export const Terms = () => {
  return (
    <TermsAndPrivacyContainer>
      <TermsContent />
    </TermsAndPrivacyContainer>
  );
};

export const Privacy = () => {
  return (
    <TermsAndPrivacyContainer>
      <PrivacyContent />
    </TermsAndPrivacyContainer>
  );
};
