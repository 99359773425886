import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { toastr } from '../../components/CustomToast';

const getInverters = createAsyncThunk(
  'inverters/getInverters',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().inverters;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }
    const inverters = await new WebAPIClient().GET('/resource/inverters');
    return { data: inverters };
  }
);

const putInverter = createAsyncThunk(
  'inverters/putInverter',
  async (inverter, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: inverters,
      } = getState().inverters;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedInverter = await new WebAPIClient().PUT(
        `/resource/inverters/${inverter.org_id}/${inverter.inverter_id}`,
        inverter
      );

      let _inverters = cloneDeep(inverters);
      remove(_inverters, { inverter_id: get(updatedInverter, 'inverter_id') });

      toastr.success({ title: 'Inverter updated' });
      return { data: concat(_inverters, updatedInverter) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getInverters, putInverter };
