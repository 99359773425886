import React from 'react';
import PropTypes from 'prop-types';

import { formatTimestamp } from '../../../../helpers/date';
import { displaykW } from '../../../../helpers/display-energy';
import BaseTooltip from '../../tooltips/BaseTooltip';

function Tooltip({ active, payload, label, timezone, meter }) {
  const resourceName = (resource) => {
    if (resource.dataKey.startsWith('meter')) {
      return meter.name;
    }
  };

  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={formatTimestamp(Number(label), timezone, 'MMM Do h:mm A')}
      getName={resourceName}
      getValue={(resource) => displaykW(resource?.value)}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  meter: PropTypes.object,
  inverters: PropTypes.array,
};

export default Tooltip;
