import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';

import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import ProductionSumItem from '../../Site/Dashboard/ProductionSumItem';
import WidgetLoader from '../../../../components/widgets/Loader';
import {
  allTimeSum,
  lastMonthSum,
  thisMonthActual,
} from '../../../../helpers/performance-data';

const BottomContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '4px',
  marginLeft: '8px',
}));

const BottomPanel = styled('div')(({ theme }) => ({
  height: 10,
  width: '100%',
  bottom: 0,
  backgroundColor: theme.veregy_colors.green,
}));

function ProductionSums(props) {
  const { meter } = props;
  const [productionSums, setProductionSums] = useState({
    today: 0,
    thisMonth: 0,
    lastMonth: 0,
    allTime: 0,
  });

  useEffect(() => {
    if (!isEmpty(meter)) {
      const thisMonth = thisMonthActual([meter]);
      const sums = {
        today: meter.energy_stats.today,
        thisMonth,
        lastMonth: lastMonthSum(
          concat(
            [meter.energy_stats.production],
            [meter.energy_stats.adjusted_production]
          )
        ),
        allTime:
          allTimeSum(
            concat(
              [meter.energy_stats.production],
              [meter.energy_stats.adjusted_production]
            )
          ) + thisMonth,
      };

      setProductionSums(sums);
    }
  }, [meter]);

  return (
    <Card raised>
      <Grid container spacing={1} sx={{ px: 2, pt: 2 }}>
        <ProductionSumItem label='Today' value={productionSums.today} />
        <ProductionSumItem
          label='This Month'
          value={productionSums.thisMonth}
        />
        <ProductionSumItem
          label='Last Month'
          value={productionSums.lastMonth}
        />
        <ProductionSumItem label='All Time' value={productionSums.allTime} />
      </Grid>
      <BottomContent>
        <Typography align='left' variant='h5'>
          Production
        </Typography>
        {isEmpty(meter) ? <WidgetLoader /> : null}
      </BottomContent>
      <BottomPanel />
    </Card>
  );
}

ProductionSums.propTypes = {
  meter: PropTypes.object,
};

export default ProductionSums;
