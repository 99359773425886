import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function CountGrid({ number, name }) {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant='h3' sx={{ pb: 1 }}>
        {number}
      </Typography>
      <Typography variant='body1' color='textSecondary'>
        {name}
      </Typography>
    </Box>
  );
}

CountGrid.propTypes = {
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default CountGrid;
