import React from 'react';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';

import useSite from '../../../../store/hooks/useSite';
import useMeter from '../../../../store/hooks/useMeter';
import useSensor from '../../../../store/hooks/useSensor';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import useView from '../../../../store/hooks/useView';

import SensorInfo from './SensorInfo';
import LatestData from './LatestData';
import ViewStack from '../../../../components/ViewStack';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import SensorChart from '../../../../components/charts/dashboard/SensorChart';

const TREND = 'trend';
const ALARMS = 'alarms';
const LATEST = 'latest';
const VIEWS = [TREND, ALARMS, LATEST];

function SensorDashboard() {
  const { id } = useSelector((state) => state.pages.sensor);
  const sensor = useSensor(id);
  const meter = useMeter(sensor.meter_id);
  const site = useSite(meter.site_id);
  const alarms = useDeviceAlarms(id);
  const selectedView = useView(VIEWS);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ width: '100%', pt: 1, px: 1, mb: 6 }}>
      <Grid size={{ xs: 12 }} display='flex' justifyContent='center'>
        <SensorInfo sensor={sensor} alarms={alarms} />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ViewStack selectedView={selectedView} views={VIEWS} />
      </Grid>
      {selectedView === TREND && (
        <Grid size={{ xs: 12 }}>
          <Card raised sx={{ p: 1 }}>
            <SensorChart site={site} sensor={sensor} hideAxisLabels={false} />
          </Card>
        </Grid>
      )}
      {selectedView === ALARMS && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={sensor} alarms={alarms} />
        </Grid>
      )}
      {selectedView === LATEST && <LatestData />}
    </Grid>
  );
}

export default SensorDashboard;
