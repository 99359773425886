import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import WebAPIClient, { errorResponseToastr } from '../../../../api';
import { compileChartData, prepareCsvData } from '../prep-data';
import SelectRange, { RANGES } from '../../selectors/SelectRange';
import SelectAttributes from '../../selectors/SelectAttributes';
import SelectTimezone from '../../selectors/SelectTimezone';
import GenerateCSVButton from '../../buttons/GenerateCSVButton';
import ChartContainer from '../ChartContainer';
import ChartHeader from '../ChartHeader';

const ATTRIBUTES = [
  'LifetimeEnergy',
  'DayEnergy',
  'PowerFactor',
  'MaxPower',
  'ActivePower',
  'AppPower',
  'GridVoltage1_2',
  'GridVoltage2_3',
  'GridVoltabe1_3',
  'GridCurrent1',
  'GridCurrent2',
  'GridCurrent3',
  'DCVolts_MPPT1',
  'DCAmps_MPPT1',
  'DCVolts_MPPT2',
  'DCAmps_MPPT2',
  'GridFreq',
  'HeatSinkTemp',
  'InverterTemp',
  'InverterAlarm',
  'InverterState',
  'Pfault',
  'WarningAlarm',
  'FaultAlarm0',
  'FaultAlarm1',
  'FaultAlarm2',
  'FaultAlarm3',
  'FaultAlarm4',
];

function InverterChart({ site, inverter }) {
  const [initialLoad, setInitialLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState({});
  const [range, setRange] = useState(RANGES[1]);
  const [attributes, setAttrbutes] = useState([ATTRIBUTES[4]]);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);

  // set timezone
  useEffect(() => {
    if (!isEmpty(site.timezone)) {
      setTimezone(site.timezone);
    }
  }, [site.timezone]);

  // update range, after timezone updates
  useEffect(() => {
    if (timezone?.offset) {
      setRange((r) => {
        if (r.key === 6) {
          return { ...r, offset: timezone.offset };
        } else {
          return { ...r, ...r.func(timezone.zone), offset: timezone.offset };
        }
      });
    }
  }, [timezone]);

  // set default chart data
  useEffect(() => {
    if (
      !initialLoad &&
      !loading &&
      rawData.length === 0 &&
      range?.start &&
      range?.end &&
      range.offset
    ) {
      fetchRawData(range);
      setInitialLoad(true);
    }

    // eslint-disable-next-line
  }, [loading, range, rawData]);

  const fetchRawData = async (range) => {
    setLoading(true);

    new WebAPIClient()
      .GET(
        `/resource/timestream-device/${
          inverter.org_id
        }/${range.start.valueOf()}/${range.end.valueOf()}`,
        {
          attributes: JSON.stringify(attributes),
          deviceId: inverter.inverter_id,
        }
      )
      .then((payload) => {
        setRawData(payload);
      })
      .catch((err) => {
        errorResponseToastr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!loading && !isEmpty(rawData)) {
      setChartData(compileChartData(rawData));
    }
  }, [rawData, loading]);

  const handleSelectRange = (newRange, fetchData) => {
    setRange(newRange);
    if (fetchData) {
      fetchRawData(newRange);
    }
  };

  const dataKeys = map(
    attributes,
    (attribute) => attribute + '_I' + inverter.parent_index
  );
  return (
    <>
      <ChartHeader>
        <SelectAttributes
          selectedAttributes={attributes}
          setSelectedAttributes={setAttrbutes}
          attributes={ATTRIBUTES}
        />

        <SelectRange
          range={range}
          setRange={handleSelectRange}
          timezone={timezone}
        />

        <SelectTimezone
          selectedTimezone={timezone}
          setSelectedTimezone={setTimezone}
        />

        <Tooltip title='Fetch Data' placement='top'>
          <IconButton onClick={() => fetchRawData(range)}>
            <FontAwesomeIcon icon={['fal', 'cloud-download']} />
          </IconButton>
        </Tooltip>
        <GenerateCSVButton
          generateCsvString={() =>
            prepareCsvData(chartData, attributes, `_I${inverter.parent_index}`)
          }
          filename={`${inverter.name} Data (${dayjs().format(
            'ddd MMM DD YYYY'
          )})`}
        />
      </ChartHeader>
      <ChartContainer
        dataKeys={dataKeys}
        timezone={timezone}
        range={range}
        loading={loading}
        chartData={chartData}
      />
    </>
  );
}

InverterChart.propTypes = {
  site: PropTypes.object,
  inverter: PropTypes.object,
};

export default InverterChart;
