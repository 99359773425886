import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { displaykW } from '../../../../helpers/display-energy';

function ProductionSumItem({ label, value }) {
  return (
    <>
      <Grid size={{ xs: 5 }}>
        <Typography variant='body1' align='left'>
          {label}
        </Typography>
      </Grid>
      <Grid size={{ xs: 7 }}>
        <Typography variant='body1' align='right'>
          {displaykW(value, true)}
        </Typography>
      </Grid>
    </>
  );
}

ProductionSumItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
};

export default ProductionSumItem;
