import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

import useOrganization from '../../../../store/hooks/useOrganization';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import useView from '../../../../store/hooks/useView';
import ROLES from '../../../../constants/roles';
import ViewStack from '../../../../components/ViewStack';
import Kiosk from '../../../../components/Configuration/Kiosk';
import MembershipsTable from '../../../../components/table/tables/MembershipsTable';
import AlarmsConfigurationTable from '../../../../components/table/tables/AlarmsConfigurationTable';
import LicensesTable from './LicensesTable';

const MEMBERSHIPS = 'memberships';
const ALARMS = 'alarms';
const KIOSK = 'kiosk';
const LICENSES = 'licenses';

export default function Configuration() {
  const { id } = useSelector((state) => state.pages.organization);
  const { organization } = useOrganization(id);

  const isOrgAdmin = useVerifyOrgRole(id, ROLES.ADMIN.value);
  const isOrgEditor = useVerifyOrgRole(id, ROLES.EDITOR.value);

  const [views, setViews] = useState([MEMBERSHIPS, ALARMS, KIOSK]);
  const selectedView = useView(views);

  useEffect(() => {
    if (isOrgAdmin) {
      setViews([MEMBERSHIPS, ALARMS, LICENSES, KIOSK]);
    } else {
      setViews([MEMBERSHIPS, ALARMS, KIOSK]);
    }
  }, [isOrgAdmin, isOrgEditor]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      sx={{ maxWidth: '100%', pt: 1, px: 1, mb: 6 }}>
      <Grid size={{ xs: 12 }}>
        <ViewStack selectedView={selectedView} views={views} />
      </Grid>
      {selectedView === MEMBERSHIPS && (
        <Grid
          size={{ xs: 12 }}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            maxWidth: '96vw',
          }}>
          <MembershipsTable orgId={organization?.org_id} />
        </Grid>
      )}
      {selectedView === ALARMS && (
        <AlarmsConfigurationTable
          resource={organization}
          typeSelect={true}
          isOrgEditor={isOrgEditor}
        />
      )}
      {selectedView === KIOSK && (
        <Kiosk resourceId={organization?.org_id} orgId={organization?.org_id} />
      )}
      {selectedView === LICENSES && (
        <Grid
          size={{ xs: 12 }}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            maxWidth: '96vw',
          }}>
          <LicensesTable orgId={organization?.org_id} />
        </Grid>
      )}
    </Grid>
  );
}
