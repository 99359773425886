import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function SelectReportTemplate(props) {
  const { selectedTemplate, setSelectedTemplate } = props;
  const { templates } = useSelector((state) => state.reports);
  const [template, setTemplate] = useState('');

  useEffect(() => {
    setTemplate(selectedTemplate);
  }, [selectedTemplate]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedTemplate(e.target.value);
  };

  return (
    <Card sx={{ width: '100%', maxWidth: 300 }}>
      <CardContent sx={{ p: 0, pb: '0 !important' }}>
        <FormControl variant='filled' size='small' sx={{ width: '100%' }}>
          <InputLabel id='template-label'>Report Template</InputLabel>
          <Select
            label='Report Template'
            labelId='template-label'
            id='template-select'
            disableUnderline
            value={template}
            onChange={handleSelect}
            sx={{
              backgroundColor: 'unset',
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            MenuProps={{
              MenuListProps: { dense: true, disablePadding: true },
            }}>
            <MenuItem id='empty-menu-item' value=''>
              <ListItemText
                primary='None'
                slotProps={{
                  primary: { variant: 'body1', py: 1 },
                }}
              />
            </MenuItem>
            {templates.map((template) => {
              return (
                <MenuItem key={template.title} value={template.title}>
                  <ListItemText
                    primary={template.title}
                    slotProps={{
                      primary: { variant: 'body1', py: 1 },
                    }}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
}

SelectReportTemplate.propTypes = {
  selectedTemplate: PropTypes.string,
  setSelectedTemplate: PropTypes.func,
};

export default SelectReportTemplate;
