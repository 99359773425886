import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import WebAPIClient, { errorResponseToastr } from '../../../../api';
import { compileChartData, prepareCsvData } from '../prep-data';
import SelectRange, { RANGES } from '../../selectors/SelectRange';
import SelectAttributes from '../../selectors/SelectAttributes';
import SelectTimezone from '../../selectors/SelectTimezone';
import GenerateCSVButton from '../../buttons/GenerateCSVButton';
import ChartHeader from '../ChartHeader';
import ChartContainer from '../ChartContainer';

const ATTRIBUTES = [
  'kW_Demand_System_Now',
  'kW_System_Net',
  'kW_L1',
  'kW_L2',
  'kW_L3',
  'Volts_Line_To_Line_Average',
  'Volts_Line_To_Neutral_Average',
  'Volts_L1_to_L2',
  'Volts_L2_to_L3',
  'Volts_L1_to_L3',
  'Volts_L1_to_Neutral',
  'Volts_L2_to_Neutral',
  'Volts_L3_to_Neutral',
  'Amps_System_Average',
  'Amps_L1',
  'Amps_L2',
  'Amps_L3',
  'EM_Status',
  'EM_NetworkStatus',
];

function MeterChart({ site, meter }) {
  const [initialLoad, setInitialLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState({});
  const [range, setRange] = useState(RANGES[1]);
  const [attributes, setAttrbutes] = useState([ATTRIBUTES[1]]);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);

  // set timezone
  useEffect(() => {
    if (!isEmpty(site.timezone)) {
      setTimezone(site.timezone);
    }
  }, [site.timezone]);

  // update range, after timezone updates
  useEffect(() => {
    if (timezone?.offset) {
      setRange((r) => {
        if (r.key === 6) {
          return { ...r, offset: timezone.offset };
        } else {
          return { ...r, ...r.func(timezone.zone), offset: timezone.offset };
        }
      });
    }
  }, [timezone]);

  // set default chart data
  useEffect(() => {
    if (
      !initialLoad &&
      !loading &&
      rawData.length === 0 &&
      range?.start &&
      range?.end &&
      range.offset
    ) {
      fetchRawData(range);
      setInitialLoad(true);
    }
    // eslint-disable-next-line
  }, [initialLoad, loading, range, rawData]);

  const fetchRawData = async (range) => {
    setLoading(true);

    new WebAPIClient()
      .GET(
        `/resource/timestream-device/${
          meter.org_id
        }/${range.start.valueOf()}/${range.end.valueOf()}`,
        {
          attributes: JSON.stringify(attributes),
          deviceId: meter.meter_id,
        }
      )
      .then((payload) => {
        setRawData(payload);
      })
      .catch((err) => {
        errorResponseToastr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!loading && !isEmpty(rawData)) {
      setChartData(compileChartData(rawData));
    }
  }, [rawData, loading]);

  const handleSelectRange = (newRange, fetchData) => {
    setRange(newRange);
    if (fetchData) {
      fetchRawData(newRange);
    }
  };

  return (
    <>
      <ChartHeader>
        <SelectAttributes
          selectedAttributes={attributes}
          setSelectedAttributes={setAttrbutes}
          attributes={ATTRIBUTES}
        />
        <SelectRange
          range={range}
          setRange={handleSelectRange}
          timezone={timezone}
        />
        <SelectTimezone
          selectedTimezone={timezone}
          setSelectedTimezone={setTimezone}
        />
        <Tooltip title='Fetch Data' placement='top'>
          <IconButton onClick={() => fetchRawData(range)}>
            <FontAwesomeIcon icon={['fal', 'cloud-download']} />
          </IconButton>
        </Tooltip>
        <GenerateCSVButton
          generateCsvString={() => prepareCsvData(chartData, attributes, null)}
          filename={`${meter.name} Data (${dayjs().format('ddd MMM DD YYYY')})`}
        />
      </ChartHeader>
      <ChartContainer
        dataKeys={attributes}
        timezone={timezone}
        range={range}
        loading={loading}
        chartData={chartData}
      />
    </>
  );
}

MeterChart.propTypes = {
  site: PropTypes.object,
  meter: PropTypes.object,
};

export default MeterChart;
