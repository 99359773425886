import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { setWebsocketOrgId } from '../../../store/pages';
import useMeter from '../../../store/hooks/useMeter';
import usePageId from '../../../store/hooks/usePageId';
import useTab from '../../../store/hooks/useTab';
import useTimeseriesData from '../../../store/hooks/useTimeseriesData';
import TabBar, {
  ANALYSIS,
  CONFIGURATION,
  DASHBOARD,
} from '../../../components/tab/TabBar';
import Analysis from './Analysis';
import MeterDashboard from './Dashboard';
import AlarmConfigurationPage from '../../../components/AlarmConfigurationPage';

const PAGE = 'meter';
const TABS = [DASHBOARD, ANALYSIS, CONFIGURATION];

export default function Meter() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { id } = useSelector((state) => state.pages.meter);
  const meter = useMeter(id);
  usePageId(id, PAGE);
  useTimeseriesData(id);

  const [tabs, setTabs] = useState([DASHBOARD, CONFIGURATION]);
  const selectedTab = useTab(tabs);

  useEffect(() => {
    if (meter?.org_id) dispatch(setWebsocketOrgId(meter.org_id));
  }, [meter?.org_id, dispatch]);

  useEffect(() => {
    if (isMobile) setTabs([DASHBOARD, CONFIGURATION]);
    else setTabs(TABS);
  }, [isMobile]);

  return (
    <TabBar tabs={tabs} selectedTab={selectedTab}>
      <MeterDashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <AlarmConfigurationPage tab={CONFIGURATION} resource={meter} />
    </TabBar>
  );
}
