import React from 'react';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid2';

export const ANALOG = 'Analog';
export const BINARY = 'Binary';
export const MULTI_STATE = 'Multi State';

function ChipSelectGrid(props) {
  const {
    selectedChip,
    setSelectedChip,
    availableChips = [ANALOG, BINARY, MULTI_STATE],
  } = props;

  const handleChipClick = (chip) => () => {
    setSelectedChip((prev) => {
      if (prev.includes(chip)) {
        return prev.filter((c) => c !== chip);
      }
      return [...prev, chip];
    });
  };

  return (
    <Grid
      size={{ xs: 12 }}
      sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
      {availableChips.map((chip) => (
        <Chip
          key={chip}
          label={chip}
          onClick={handleChipClick(chip)}
          color={selectedChip.includes(chip) ? 'primary' : 'default'}
        />
      ))}
    </Grid>
  );
}

export default ChipSelectGrid;
