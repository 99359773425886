import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';

import {
  EditingState,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import { openConfirmDialog } from '../../../store/dialogs';
import {
  deleteUserReport,
  postUserReport,
} from '../../../store/reports/_reports';
import useCompareOrgSiteName from '../../../store/hooks/useCompareOrgSiteName';
import useCompareReportName from '../../../store/hooks/useCompareReportName';
import BaseTable from '../../../components/table/tables/BaseTable';
import SelectOrganization from '../../../components/selectors/SelectOrganization';
import SelectReportTemplate from '../../../components/charts/selectors/SelectReportTemplate';
import {
  CheckBoxTypeProvider,
  OrgSiteIdNameLinkTypeProvider,
  ReportTypeProvider,
} from '../../../components/table/providers';

const ReportSubscriptionsTable = () => {
  const dispatch = useDispatch();
  const compareOrgSiteName = useCompareOrgSiteName();
  const compareReportName = useCompareReportName();

  const organizations = useSelector((state) => state.organizations.data);
  const { item: user, memberships } = useSelector((state) => state.user);

  const { data: reports, userReports } = useSelector((state) => state.reports);
  const [selectedOrganization, setSelectedOrganization] = useState({
    org_id: '',
  });
  const [userOrganizations, setUserOrganizations] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [relations, setRelations] = useState([]);

  const [columns] = useState([
    { title: 'Subscribed', name: 'subscribed', width: 0.15 },
    { title: 'Site', name: 'device_id', width: 0.4 },
    { title: 'Report', name: 'report_id', width: 0.35 },
  ]);

  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'device_id', compare: compareOrgSiteName },
    { columnName: 'report_id', compare: compareReportName },
  ]);

  useEffect(() => {
    let _userOrgs = map(memberships, (membership) => {
      return find(organizations, { org_id: membership.org_id });
    });
    setUserOrganizations(_userOrgs);
  }, [user, memberships, organizations]);

  useEffect(() => {
    let _relations = [...reports];

    if (selectedOrganization?.org_id) {
      _relations = filter(_relations, {
        org_id: selectedOrganization.org_id,
      });
    }

    if (selectedTemplate) {
      _relations = filter(_relations, (relation) => {
        return relation.name === selectedTemplate;
      });
    }

    _relations = map(_relations, (relation) => {
      let _userReport = find(userReports, { report_id: relation.report_id });
      return {
        ...relation,
        subscribed: !!_userReport,
        report_id: relation.report_id,
      };
    });

    setRelations(_relations);
  }, [
    user,
    memberships,
    reports,
    userReports,
    selectedOrganization,
    selectedTemplate,
  ]);

  const handleClick = (attrs) => {
    const message = (
      <p>{`Are you sure you want to ${
        attrs?.subscribed ? 'unsubscribe from' : 'subscribe to'
      } this report?`}</p>
    );
    dispatch(
      openConfirmDialog({
        title: `${attrs?.subscribed ? 'Unsubscribe' : 'Subscribe'} to Report`,
        message,
        onConfirm: () =>
          dispatch(
            attrs?.subscribed
              ? deleteUserReport({
                  report_id: attrs.report_id,
                })
              : postUserReport({
                  org_id: attrs.org_id,
                  device_id: attrs.device_id,
                  report_id: attrs.report_id,
                })
          ),
      })
    );
  };

  const handleSelectOrg = (orgId) => {
    const organization = find(userOrganizations, {
      org_id: orgId,
    });
    if (organization) {
      setSelectedOrganization(organization);
    } else {
      setSelectedOrganization({ org_id: '' });
    }
  };

  return (
    <>
      <Grid
        size={{ xs: 12 }}
        display='flex'
        justifyContent='center'
        sx={{ maxWidth: '100vw' }}>
        <Box sx={{ maxWidth: 650, width: '100%' }}>
          <Stack
            direction='row'
            spacing={1}
            justifyContent='flex-start'
            alignItems='flex-end'>
            <Card sx={{ width: '100%' }}>
              <SelectOrganization
                orgId={selectedOrganization.org_id}
                setOrgId={handleSelectOrg}
                organizations={userOrganizations}
              />
            </Card>
            <SelectReportTemplate
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />
          </Stack>
        </Box>
      </Grid>
      <Grid
        size={{ xs: 12 }}
        display='flex'
        justifyContent='center'
        sx={{ maxWidth: '100vw' }}>
        <Card raised sx={{ maxWidth: '96vw', p: 1, pb: 0.5 }}>
          <BaseTable
            minWidth={600}
            rows={relations}
            columns={columns}
            editColumn={<TableEditColumn width={5} />}>
            <CheckBoxTypeProvider
              for={['subscribed']}
              handleClick={handleClick}
            />
            <OrgSiteIdNameLinkTypeProvider for={['device_id']} />
            <ReportTypeProvider for={['report_id']} />

            <EditingState />

            <SortingState />
            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />

            <PagingState defaultCurrentPage={0} pageSize={10} />
            <IntegratedPaging />
          </BaseTable>
        </Card>
      </Grid>
    </>
  );
};

export default ReportSubscriptionsTable;
