import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { navigate } from '../../../store/pages';
import ROLES, { findRole } from '../../../constants/roles';

const UserMembershipsList = () => {
  const dispatch = useDispatch();
  const { memberships: allMemberships } = useSelector((state) => state.user);
  const { data: organizations } = useSelector((state) => state.organizations);

  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    if (!isEmpty(allMemberships) && !isEmpty(organizations)) {
      let _memberships = map(allMemberships, (membership) => {
        const organization = find(organizations, {
          org_id: membership.org_id,
        });
        return {
          role: membership.role,
          isPortfolio: organization.is_portfolio,
          orgId: organization.org_id,
          orgName: organization.name,
        };
      });

      _memberships.sort((a, b) => {
        if (a.isPortfolio !== b.isPortfolio) {
          return a.isPortfolio === true ? -1 : 1;
        }
        if (a.orgName < b.orgName) return -1;
        if (a.orgName > b.orgName) return 1;
        return 0;
      });

      setMemberships(_memberships);
    }
  }, [allMemberships, organizations]);

  const handleClick = (membership) => {
    const page = membership.isPortfolio ? 'portfolio' : 'organization';
    dispatch(
      navigate({
        page,
        id: membership.orgId,
      })
    );
  };

  return (
    <Grid size={{ xs: 12 }} display='flex' justifyContent='center'>
      <Card raised sx={{ maxWidth: 650, width: '100%' }}>
        <List disablePadding>
          <ListItem sx={{ py: 1 }}>
            <ListItemText
              primary='Organization'
              sx={{ pl: 2 }}
              slotProps={{
                primary: {
                  color: 'textSecondary',
                  variant: 'body2',
                },
              }}
            />
            <ListItemText
              primary='Role'
              sx={{ pr: 4 }}
              slotProps={{
                primary: {
                  align: 'right',
                  color: 'textSecondary',
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
          <Divider sx={{ mx: 1 }} />
          {memberships.map((membership) => {
            const roleName = findRole(membership.role);
            const role = ROLES[roleName];
            const icon = membership.isPortfolio ? 'folder' : 'buildings';
            return (
              <ListItemButton
                onClick={() => handleClick(membership)}
                key={membership.orgId}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  py: '6px',
                }}>
                <Icon
                  fontSize='small'
                  sx={{
                    height: 25,
                    width: 25,
                    mb: '4px',
                    color: (theme) => theme.palette.textSecondary,
                  }}>
                  <FontAwesomeIcon icon={['fal', icon]} />
                </Icon>
                <ListItemText
                  primary={membership.orgName}
                  slotProps={{
                    primary: {
                      color: 'textSecondary',
                      sx: { pl: 2 },
                    },
                  }}
                />
                <Chip
                  icon={<FontAwesomeIcon icon={['fal', role.icon]} />}
                  label={lowerCase(roleName)}
                  sx={{ minWidth: '6rem' }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Card>
    </Grid>
  );
};

export default UserMembershipsList;
