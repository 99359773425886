import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { setWebsocketOrgId } from '../../../store/pages';
import usePageId from '../../../store/hooks/usePageId';
import useInverter from '../../../store/hooks/useInverter';
import useTab from '../../../store/hooks/useTab';
import useTimeseriesData from '../../../store/hooks/useTimeseriesData';
import TabBar, {
  ANALYSIS,
  CONFIGURATION,
  DASHBOARD,
} from '../../../components/tab/TabBar';
import InverterDashboard from './Dashboard';
import Analysis from './Analysis';
import AlarmConfigurationPage from '../../../components/AlarmConfigurationPage';

const PAGE = 'inverter';
const TABS = [DASHBOARD, ANALYSIS, CONFIGURATION];

export default function Inverter() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { id } = useSelector((state) => state.pages.inverter);
  const inverter = useInverter(id);
  usePageId(id, PAGE);
  useTimeseriesData(id);

  const [tabs, setTabs] = useState([DASHBOARD, CONFIGURATION]);
  const selectedTab = useTab(tabs);

  useEffect(() => {
    if (inverter?.org_id) dispatch(setWebsocketOrgId(inverter.org_id));
  }, [inverter?.org_id, dispatch]);

  useEffect(() => {
    if (isMobile) setTabs([DASHBOARD, CONFIGURATION]);
    else setTabs(TABS);
  }, [isMobile]);

  return (
    <TabBar tabs={tabs} selectedTab={selectedTab}>
      <InverterDashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <AlarmConfigurationPage tab={CONFIGURATION} resource={inverter} />
    </TabBar>
  );
}
