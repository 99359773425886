import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { setWebsocketOrgId } from '../../../store/pages';
import useSite from '../../../store/hooks/useSite';
import usePageId from '../../../store/hooks/usePageId';
import useTimeseriesData from '../../../store/hooks/useTimeseriesData';

import TabBar, {
  ANALYSIS,
  CONFIGURATION,
  DASHBOARD,
} from '../../../components/tab/TabBar';
import Dashboard from './Dashboard';
import Configuration from './Configuration';
import Analysis from './Analysis';
import useTab from '../../../store/hooks/useTab';

const PAGE = 'site';
const TABS = [DASHBOARD, ANALYSIS, CONFIGURATION];

export default function Site() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { id } = useSelector((state) => state.pages.site);
  const site = useSite(id);
  usePageId(id, PAGE);
  useTimeseriesData(id);

  const [tabs, setTabs] = useState([DASHBOARD, CONFIGURATION]);
  const selectedTab = useTab(tabs);

  useEffect(() => {
    if (site?.org_id) dispatch(setWebsocketOrgId(site.org_id));
  }, [site?.org_id, dispatch]);

  useEffect(() => {
    if (isMobile) setTabs([DASHBOARD, CONFIGURATION]);
    else setTabs(TABS);
  }, [isMobile]);

  return (
    <TabBar tabs={tabs} selectedTab={selectedTab}>
      <Dashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <Configuration tab={CONFIGURATION} />
    </TabBar>
  );
}
