import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import head from 'lodash/head';

const useView = (views) => {
  const location = useLocation();
  const [selectedView, setSelectedView] = useState(head(views));

  useEffect(() => {
    const view = location.hash.split('=')[1];
    if (views.includes(view)) {
      setSelectedView(view);
    } else {
      setSelectedView(head(views));
    }
  }, [views, location.hash]);

  return selectedView;
};

export default useView;
