import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import numeral from 'numeral';
import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';

import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { StyledTd } from '../../tooltips/BaseTooltip';

const sumValues = (type, payload) => {
  return reduce(
    filter(payload, (datapoint) => includes(datapoint.dataKey, type)),
    (acc, datapoint) => {
      return acc + get(datapoint, 'value', 0);
    },
    0
  );
};

function Tooltip({ active, payload, label, unit }) {
  const theme = useTheme();
  if (active && payload && payload.length) {
    let actual = sumValues('actual', payload);
    let expected = sumValues('expected', payload);

    return (
      <Card raised sx={{ width: '20rem', px: 1, py: 0.5 }}>
        {label && (
          <Typography variant='body1' align='center'>
            {dayjs(Number(label)).format('YYYY MMMM')}
          </Typography>
        )}
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <StyledTd color={theme.veregy_colors.blue}>Actual</StyledTd>
              <StyledTd align='right' color={theme.veregy_colors.blue}>
                {numeral(actual).format('0,0.0') + ' ' + unit}
              </StyledTd>
            </tr>
            <tr>
              <StyledTd color={theme.veregy_colors.orange}>Expected</StyledTd>
              <StyledTd align='right' color={theme.veregy_colors.orange}>
                {numeral(expected).format('0,0.0') + ' ' + unit}
              </StyledTd>
            </tr>
            <tr>
              <StyledTd borderBottom>Production Index</StyledTd>
              <StyledTd align='right' borderBottom>
                {numeral(expected ? actual / expected : 0).format('0,0.00')}
              </StyledTd>
            </tr>
            <tr>
              <StyledTd colSpan={2} align='center'>
                Click to see all sites
              </StyledTd>
            </tr>
          </tbody>
        </table>
      </Card>
    );
  }

  return null;
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  unit: PropTypes.string,
};

export default Tooltip;
