import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { displaykW } from '../../../../helpers/display-energy';
import useSite from '../../../../store/hooks/useSite';
import useMeter from '../../../../store/hooks/useMeter';
import useInverter from '../../../../store/hooks/useInverter';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import useView from '../../../../store/hooks/useView';

import LatestData from './LatestData';
import ViewStack from '../../../../components/ViewStack';
import WidgetLoader from '../../../../components/widgets/Loader';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import InverterChart from '../../../../components/charts/dashboard/InverterChart';
import AlarmPreviewLink from '../../../../components/AlarmPreviewLink';

const BottomContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '4px',
}));

const BottomPanel = styled('div')(({ theme }) => ({
  height: 10,
  width: '100%',
  bottom: 0,
  backgroundColor: theme.veregy_colors.orange,
}));

const TREND = 'trend';
const ALARMS = 'alarms';
const LATEST = 'latest';
const VIEWS = [TREND, ALARMS, LATEST];

function InverterDashboard() {
  const { id } = useSelector((state) => state.pages.inverter);
  const inverter = useInverter(id);
  const meter = useMeter(inverter.meter_id);
  const site = useSite(meter.site_id);
  const alarms = useDeviceAlarms(id);

  const selectedView = useView(VIEWS);

  const acSize = inverter.ac_size ? inverter.ac_size + ' kW' : '-';
  const dcSize = inverter.dc_size ? inverter.dc_size + ' kW' : '-';
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ width: '100%', pt: 1, px: 1, mb: 6 }}>
      <Grid size={{ xs: 12 }} display='flex' justifyContent='center'>
        <Card raised sx={{ pt: 1 }}>
          <Stack spacing={1} direction='row' sx={{ px: 2 }}>
            <TextField
              id='state'
              label='State'
              value={get(inverter, 'state', '-')}
              variant='standard'
              slotProps={{
                input: { readOnly: true, disableUnderline: true },
              }}
            />
            <TextField
              id='today'
              label='Power Today'
              value={displaykW(get(inverter, 'today'))}
              variant='standard'
              slotProps={{
                input: { readOnly: true, disableUnderline: true },
              }}
            />
            <TextField
              id='dc-size'
              label='DC Size'
              value={dcSize}
              variant='standard'
              slotProps={{
                input: { readOnly: true, disableUnderline: true },
              }}
            />
            <TextField
              id='ac-size'
              label='AC Size'
              value={acSize}
              variant='standard'
              slotProps={{
                input: { readOnly: true, disableUnderline: true },
              }}
            />
          </Stack>
          <BottomContent>
            <AlarmPreviewLink alarms={alarms} deviceName={inverter?.name}>
              <Typography align='left' variant='h5' sx={{ ml: 2 }}>
                {inverter?.name}
              </Typography>
            </AlarmPreviewLink>
            {isEmpty(inverter) ? <WidgetLoader /> : null}
          </BottomContent>
          <BottomPanel />
        </Card>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ViewStack selectedView={selectedView} views={VIEWS} />
      </Grid>
      {selectedView === TREND && (
        <Grid size={{ xs: 12 }}>
          <Card raised sx={{ p: 1 }}>
            <InverterChart site={site} inverter={inverter} />
          </Card>
        </Grid>
      )}
      {selectedView === ALARMS && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={inverter} alarms={alarms} />
        </Grid>
      )}
      {selectedView === LATEST && <LatestData />}
    </Grid>
  );
}

export default InverterDashboard;
