import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { setWebsocketOrgId } from '../../../store/pages';
import usePageId from '../../../store/hooks/usePageId';
import useLogger from '../../../store/hooks/useLogger';
import useTimeseriesData from '../../../store/hooks/useTimeseriesData';
import TabBar, {
  ANALYSIS,
  CONFIGURATION,
  DASHBOARD,
} from '../../../components/tab/TabBar';
import Dashboard from './Dashboard';
import Analysis from './Analysis';

import AlarmConfigurationPage from '../../../components/AlarmConfigurationPage';
import useTab from '../../../store/hooks/useTab';

const PAGE = 'logger';
const TABS = [DASHBOARD, ANALYSIS, CONFIGURATION];

export default function Logger() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { id } = useSelector((state) => state.pages.logger);
  usePageId(id, PAGE);
  useTimeseriesData(id);
  const logger = useLogger(id);

  const [tabs, setTabs] = useState([DASHBOARD, CONFIGURATION]);
  const selectedTab = useTab(tabs);

  useEffect(() => {
    if (logger?.org_id) dispatch(setWebsocketOrgId(logger.org_id));
  }, [logger?.org_id, dispatch]);

  useEffect(() => {
    if (isMobile) setTabs([DASHBOARD, CONFIGURATION]);
    else setTabs(TABS);
  }, [isMobile]);

  return (
    <TabBar tabs={tabs} selectedTab={selectedTab}>
      <Dashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <AlarmConfigurationPage tab={CONFIGURATION} resource={logger} />
    </TabBar>
  );
}
