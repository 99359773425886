import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import find from 'lodash/find';
import head from 'lodash/head';
import remove from 'lodash/remove';

import { timeseries as initialState } from '../initialState';
import { refreshDeviceTimeseries } from './_timeseries';
import { buildAsyncReducers } from '../thunkTemplate';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'timeseries',
  initialState,
  reducers: {
    updateTimeseriesData: (state, { payload }) => {
      const { timestamp, meterValues, inverterValues, sensorValues } = payload;
      let allTimeseries = cloneDeep(state.data);
      const now = dayjs();

      each(meterValues, ({ meterId, value }) => {
        let timeseries = head(
          remove(allTimeseries, { device_id: meterId, data_type: 'recent' })
        );
        if (timeseries?.data) {
          timeseries.data = concat(timeseries.data, [{ timestamp, value }]);
          timeseries.lastRefresh = now;
          allTimeseries = concat(allTimeseries, timeseries);
        }
      });
      each(inverterValues, ({ inverterId, value }) => {
        let timeseries = head(
          remove(allTimeseries, { device_id: inverterId, data_type: 'recent' })
        );
        if (timeseries?.data) {
          timeseries.data = concat(timeseries.data, [{ timestamp, value }]);
          timeseries.lastRefresh = now;
          allTimeseries = concat(allTimeseries, timeseries);
        }
      });
      each(sensorValues, ({ sensorId, ...values }) => {
        let timeseries = head(
          remove(allTimeseries, { device_id: sensorId, data_type: 'recent' })
        );
        const radiationKey = find(Object.keys(values), (key) =>
          key.includes('Radiation')
        );
        const temperatureKey = find(Object.keys(values), (key) =>
          key.includes('PanelTemp')
        );
        if (timeseries?.data) {
          timeseries.data = concat(
            timeseries.data,
            {
              timestamp,
              measure_name: radiationKey,
              value: values[radiationKey],
            },
            {
              timestamp,
              measure_name: temperatureKey,
              value: values[temperatureKey],
            }
          );
          timeseries.lastRefresh = now;
          allTimeseries = concat(allTimeseries, timeseries);
        }
      });
      return { data: allTimeseries };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [refreshDeviceTimeseries]);
  },
});

// Extract each action creator by name
const { updateTimeseriesData } = actions;

// Export the reducer, either as a default or named export
export { updateTimeseriesData, refreshDeviceTimeseries };
export default reducer;
