import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import WebAPIClient, { errorResponseToastr } from '../../../../api';
import { endsWithIXorSX, roundNumber } from '../../../../helpers';
import { getFaultMessage } from '../../Inverter/Dashboard/faultAlarmMessages';
import useLogger from '../../../../store/hooks/useLogger';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import { REGISTER_OBJECTS as METER_REGISTER_OBJECTS } from '../../Meter/Dashboard/LatestData';
import { REGISTER_OBJECTS as INVERTER_REGISTER_OBJECTS } from '../../Inverter/Dashboard/LatestData';
import { REGISTER_OBJECTS as SENSOR_REGISTER_OBJECTS } from '../../Sensor/Dashboard/LatestData';
import ChipSelectGrid, {
  ANALOG,
  BINARY,
  MULTI_STATE,
} from '../../../../components/Grid/ChipSelectGrid';
import ValuesList from '../../../../components/RegisterDataGrid/ValuesList';
import WidgetLoader from '../../../../components/widgets/Loader';
import { toastr } from '../../../../components/CustomToast';

function LiveData() {
  const { id } = useSelector((state) => state.pages.logger);
  const logger = useLogger(id);
  const { meters, inverters } = useLoggerDevices(id);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [analogValues, setAnalogValues] = useState([]);
  const [binaryValues, setBinaryValues] = useState([]);
  const [multiStateValues, setMultiStateValues] = useState([]);
  const [selectedChip, setSelectedChip] = React.useState([
    ANALOG,
    BINARY,
    MULTI_STATE,
  ]);

  useEffect(() => {
    let _values = data.filter((item) => item.type === 'analogValue');
    _values = _values.map((item) => {
      if (meters.length > 1 && !endsWithIXorSX(item?.measure_name)) {
        item.measure_name += ` (meter ${item.logger_index})`;
      }
      return {
        ...item,
        value: roundNumber.format(item.measure_value),
      };
    });

    setAnalogValues(_values);
  }, [data, meters]);

  useEffect(() => {
    let _values = data.filter((item) => item.type === 'binaryValue');
    _values = _values.map((item) => {
      let value = '-';
      if (item?.measure_value === 0) {
        value = 'Inactive';
      }
      if (item?.measure_value === 1) {
        value = 'Active';
      }
      if (meters.length > 1 && !endsWithIXorSX(item?.measure_name)) {
        item.measure_name += ` (meter ${item.logger_index})`;
      }

      return { ...item, value };
    });
    setBinaryValues(_values);
  }, [data, meters]);

  useEffect(() => {
    let _values = data.filter((item) => item.type === 'multiStateValue');
    _values = _values.map((item) => {
      let value = item.measure_value;
      if (item.measure_name.includes('_I')) {
        const inverterIdx = item.measure_name[item.measure_name.length - 1];
        const inverter = find(inverters, {
          parent_index: Number(inverterIdx),
        });

        const measureName = item.measure_name.split('_')[0];
        if (measureName === 'InverterState') {
          value =
            INVERTER_REGISTER_OBJECTS.multiStateValue[1].stateText[
              item.measure_value
            ];
        } else {
          value = getFaultMessage(
            measureName,
            item.measure_value,
            inverter?.manufacturer
          );
        }
      } else if (item.measure_name.includes('_S')) {
        const measureName = item.measure_name.slice(0, -3);
        const registerObject = find(SENSOR_REGISTER_OBJECTS.multiStateValue, {
          objectName: measureName,
        });
        value = registerObject.stateText[item.measure_value];
      } else if (
        item.measure_name ===
        METER_REGISTER_OBJECTS.multiStateValue[501].objectName
      ) {
        value =
          METER_REGISTER_OBJECTS.multiStateValue[501].stateText[
            item.measure_value
          ];
        if (meters.length > 1 && !endsWithIXorSX(item?.measure_name)) {
          item.measure_name += ` (meter ${item.logger_index})`;
        }
      }
      return { ...item, value };
    });
    setMultiStateValues(_values);
  }, [data, inverters, meters]);

  const handleFetchData = async () => {
    setLoading(true);
    try {
      let payload = await new WebAPIClient().GET(
        `/resource/loggers/${logger.org_id}/${logger.logger_id}`
      );
      if (payload.length > 0) {
        payload = sortBy(payload, 'logger_index');
        setData(payload);
      } else {
        setData([]);
      }
    } catch (error) {
      if (error?.code === 'ECONNABORTED') {
        toastr.error({ title: 'Timeout Error', message: error?.message });
      } else {
        errorResponseToastr(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        size={{ xs: 12 }}
        display='flex'
        justifyContent='center'
        alignItems='center'>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {loading ? (
            <WidgetLoader delay={0} />
          ) : (
            <Tooltip
              title='force fetch'
              placement='top'
              onClick={handleFetchData}>
              <IconButton sx={{ height: 25, width: 25 }}>
                <FontAwesomeIcon size='sm' icon={['fal', 'router']} />
              </IconButton>
            </Tooltip>
          )}
          <Typography
            sx={{ minWidth: 100 }}
            variant='body1'
            color='textSecondary'>
            Fetch data directly from data logger
          </Typography>
        </Box>
      </Grid>
      <ChipSelectGrid
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
      />
      <Grid
        size={{ xs: 12, md: 6 }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}>
        {selectedChip.includes(ANALOG) && (
          <ValuesList title='Analog Values' values={analogValues} />
        )}
        {selectedChip.includes(BINARY) && (
          <ValuesList title='Binary Values' values={binaryValues} />
        )}
        {selectedChip.includes(MULTI_STATE) && (
          <ValuesList title='Multi State Values' values={multiStateValues} />
        )}
      </Grid>
    </>
  );
}

export default LiveData;
