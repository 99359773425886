import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid2';

import ROLES from '../../../../constants/roles';
import useSite from '../../../../store/hooks/useSite';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import useView from '../../../../store/hooks/useView';
import ViewStack from '../../../../components/ViewStack';
import Kiosk from '../../../../components/Configuration/Kiosk';
import AlarmsConfigurationTable from '../../../../components/table/tables/AlarmsConfigurationTable';
import SiteExpectationsTable from './SiteExpectationsTable';
import ReportsTable from '../../../../components/table/tables/ReportsTable';

const ALARMS = 'alarms';
const EXPECTATIONS = 'expectations';
const KIOSK = 'kiosk';
const REPORTS = 'reports';
const VIEWS = [ALARMS, EXPECTATIONS, REPORTS, KIOSK];

export default function Configuration() {
  const { id } = useSelector((state) => state.pages.site);
  const site = useSite(id);
  const isOrgEditor = useVerifyOrgRole(site?.org_id, ROLES.EDITOR.value);
  const selectedView = useView(VIEWS);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      sx={{ maxWidth: '100%', pt: 1, px: 1, mb: 6 }}>
      <Grid size={{ xs: 12 }}>
        <ViewStack selectedView={selectedView} views={VIEWS} />
      </Grid>
      {selectedView === ALARMS && (
        <AlarmsConfigurationTable
          resource={site}
          isOrgEditor={isOrgEditor}
          typeSelect={true}
        />
      )}
      {selectedView === EXPECTATIONS && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <SiteExpectationsTable />
        </Grid>
      )}
      {selectedView === KIOSK && (
        <Kiosk resourceId={site.site_id} orgId={site.org_id} />
      )}
      {selectedView === REPORTS && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <ReportsTable resource={site} />
        </Grid>
      )}
    </Grid>
  );
}
