import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectSensor(props) {
  const { orgId, sensorId, handleSelect } = props;

  const sensors = useSelector((state) => {
    return orgId
      ? state.sensors.data.filter((sensor) => sensor.org_id === orgId)
      : [];
  });
  const meters = useSelector((state) => state.meters.data);

  return (
    <FormControl id='sensor' variant='standard' fullWidth>
      <InputLabel id='sensor-label'>Sensor</InputLabel>
      <Select
        labelId='sensor-label'
        value={sensors?.length ? sensorId : ''}
        onChange={handleSelect}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          slotProps: {
            paper: { sx: { minWidth: 'unset !important' } },
          },
        }}
        sx={{
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        <MenuItem id='empty-menu-item' value=''>
          <ListItemText
            primary='None'
            slotProps={{
              primary: {
                variant: 'body1',
                color: 'textSecondary',
              },
            }}
          />
        </MenuItem>
        {map(sortBy(sensors, 'name'), (sensor) => {
          let meter = find(meters, { meter_id: sensor.meter_id });
          return (
            <MenuItem key={sensor.sensor_id} value={sensor.sensor_id}>
              <ListItemText
                primary={meter.name + ' - ' + sensor.name}
                slotProps={{
                  primary: {
                    variant: 'body1',
                  },
                }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectSensor.propTypes = {
  sensorId: PropTypes.string,
  sensors: PropTypes.array,
  handleSelect: PropTypes.func,
};

export default SelectSensor;
