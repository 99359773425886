import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  FilteringState,
  PagingState,
  SelectionState,
  IntegratedSelection,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';

import {
  MeterIdNameLinkTypeProvider,
  SiteIdNameLinkTypeProvider,
} from '../../table/providers';

import ROUTES from '../../../constants/routes';
import Tooltip from './Tooltip';
import DialogTitleOptions from '../../Dialogs/DialogTitleOptions';
import useCompareOrgName from '../../../store/hooks/useCompareOrgName';
import useOrgNamePredicate from '../../../store/hooks/useOrgNamePredicate';
import BaseTable from '../../table/tables/BaseTable';

const getColumns = (pathname) => {
  switch (pathname) {
    case ROUTES.AUTH.LOGGER:
    case ROUTES.AUTH.SITE:
      return [
        { title: 'Meter', name: 'meter_id', width: 0.5 },
        { title: 'Inverter', name: 'name', width: 0.2 },
        { title: 'AC Size', name: 'ac_size', width: 0.1 },
      ];

    case ROUTES.AUTH.METER:
      return [
        { title: 'Inverter', name: 'name', width: 0.7 },
        { title: 'AC Size', name: 'ac_size', width: 0.1 },
      ];

    case ROUTES.AUTH.ORGANIZATION:
    default:
      return [
        { title: 'Site', name: 'site_id', width: 0.3 },
        { title: 'Meter', name: 'meter_id', width: 0.25 },
        { title: 'Inverter', name: 'name', width: 0.15 },
        { title: 'AC Size', name: 'ac_size', width: 0.1 },
      ];
  }
};

function SelectInverters(props) {
  const { allInverters, selectedInverters, setSelectedInverters } = props;
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const orgNamePredicate = useOrgNamePredicate();
  const compareOrgName = useCompareOrgName();

  const { data: meters } = useSelector((state) => state.meters);
  const [columns] = useState(getColumns(location.pathname));

  const [open, setOpen] = useState(false);
  const [inverters, setInverters] = useState([]);
  const [selection, setSelection] = useState([]);

  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'org_id', predicate: orgNamePredicate },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'org_id', compare: compareOrgName },
  ]);

  useEffect(() => {
    setInverters(
      map(allInverters, (inverter) => {
        const meter = find(meters, { meter_id: inverter.meter_id });
        return { ...inverter, site_id: meter?.site_id };
      })
    );
  }, [allInverters, meters]);

  useEffect(() => {
    setSelection(
      map(selectedInverters, (inverter) => {
        return findIndex(allInverters, { inverter_id: inverter.inverter_id });
      })
    );
  }, [allInverters, selectedInverters]);

  const handleSelect = () => {
    setSelectedInverters(
      map(selection, (idx) => {
        return inverters[idx];
      })
    );
    setSelection([]);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={
          <List disablePadding>
            {map(sortBy(selectedInverters, 'parent_index'), (inverter) => (
              <ListItem sx={{ p: 1, pt: 0, pb: 0 }} key={inverter.inverter_id}>
                <ListItemText
                  slotProps={{
                    primary: {
                      color: theme.palette.primary.main,
                    },
                  }}>
                  {inverter.name}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        }>
        <Button
          color='primary'
          onClick={() => setOpen(true)}
          sx={{ width: '4rem' }}>
          Inverters
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
        <DialogTitle>
          Select Inverters
          <DialogTitleOptions handleClose={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ maxWidth: 500, px: 1 }}>
          <BaseTable
            rows={inverters}
            columns={columns}
            selection
            maxWidth={500}>
            <SiteIdNameLinkTypeProvider for={['site_id']} />
            <MeterIdNameLinkTypeProvider for={['meter_id']} />

            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />

            <FilteringState />
            <SortingState defaultSorting={[{ columnName: 'name' }]} />
            <PagingState defaultCurrentPage={0} pageSize={10} />

            <IntegratedFiltering
              columnExtensions={integratedFilteringColumnExtensions}
            />
            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />
            <IntegratedSelection />
            <IntegratedPaging />
          </BaseTable>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' onClick={handleSelect}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SelectInverters.propTypes = {
  allInverters: PropTypes.array,
  selectedInverters: PropTypes.array,
  setSelectedInverters: PropTypes.func,
};

export default SelectInverters;
