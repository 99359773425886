import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import traverse from 'traverse';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import find from 'lodash/find';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toLower from 'lodash/toLower';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view';

import { navigate } from '../../../store/pages';
import useNodes from '../../../store/hooks/useNodes';
import SearchBar from './SearchBar';

const Label = styled('div')({ display: 'flex', alignItems: 'center' });

const StyledTreeItem = styled(TreeItem)(({ theme }) => {
  return {
    maxWidth: 300,
    [`& .${treeItemClasses.root}`]: {},
    [`& .${treeItemClasses.groupTransition}`]: {},
    [`& .${treeItemClasses.content}`]: {
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: theme.spacing(1),
    },
    [`& .${treeItemClasses.expanded}`]: {},
  };
});

const CustomIcon = (props) => {
  const { icon } = props;
  const theme = useTheme();
  return (
    <FontAwesomeIcon icon={['fal', icon]} color={theme.palette.textSecondary} />
  );
};

const CollapseIcon = () => {
  return <CustomIcon icon='caret-down' />;
};

const ExpandIcon = () => {
  return <CustomIcon icon='caret-right' />;
};

const findNode = (nodes, key, id) => {
  return first(
    traverse(nodes).reduce(function (acc, node) {
      return this.notLeaf && node[key] === id
        ? acc.concat({ ...node, level: this.level })
        : acc;
    }, [])
  );
};

const findAllNodes = (text, items) => {
  var i = 0,
    found,
    result = [];

  for (; i < items.length; i++) {
    const label = get(items, `${i}.label`);
    if (toLower(label).includes(toLower(text))) {
      result.push(items[i]);
    } else if (isArray(items[i].children)) {
      found = findAllNodes(text, items[i].children);
      if (found.length) {
        result = result.concat(found);
      }
    }
  }
  return uniqBy(result, 'id');
};

export default function NavTree() {
  const dispatch = useDispatch();
  const nodes = useNodes();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const user = useSelector((state) => state.user.item);
  const [searchText, setSearchText] = useState('');
  const [treeNodes, setTreeNodes] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!isEmpty(nodes)) {
      if (searchText === '') {
        setTreeNodes(nodes);
      } else {
        setTreeNodes(findAllNodes(searchText, nodes));
      }
    }
  }, [nodes, searchText]);

  useEffect(() => {
    if (user) {
      setExpanded((_expanded) => {
        let expanded = cloneDeep(_expanded);
        expanded = uniq(expanded);

        let defaultOrgNode = find(treeNodes, {
          id: user?.default_organization,
        });
        if (defaultOrgNode && !includes(expanded, defaultOrgNode.itemId)) {
          expanded.push(defaultOrgNode.itemId);
        }
        return expanded;
      });
    }
  }, [user, treeNodes]);

  const handleLabelClick = (e, itemId) => {
    if (includes(expanded, itemId)) {
      e.stopPropagation();
    }

    setSelected([itemId]);
    let node = findNode(nodes, 'itemId', itemId);
    dispatch(navigate({ page: node.resourceType, id: node.id }));
  };

  const handleExpandedItemsChange = (e, itemIds) => {
    setExpanded(itemIds);
  };

  const mapNodes = (_nodes) => {
    return _nodes.map((node) => {
      const { children, itemId, icon, label, attr } = node;
      const _children = sortBy(children, [
        (child) => {
          return child.type.includes('-inverter');
        },
        (child) => {
          return child.type.includes('-sensor');
        },
        (child) => {
          return child.type.includes('-meter');
        },
      ]);

      return (
        <StyledTreeItem
          itemId={itemId}
          key={itemId}
          label={
            <Label
              sx={{ height: isMobile ? 35 : 25 }}
              id={itemId}
              onClick={(e) => handleLabelClick(e, itemId)}>
              {icon && (
                <IconButton sx={{ p: 0, pr: 1 }}>
                  <FontAwesomeIcon
                    id='tree-item-label'
                    icon={icon}
                    size='2xs'
                  />
                </IconButton>
              )}
              <ListItemText
                secondary={label}
                sx={{
                  '& .MuiTypography-root': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    maxWidth: '100%',
                  },
                }}
              />
              {attr && <Chip label={attr} sx={{ height: 20, mr: 1 }} />}
            </Label>
          }>
          {_children && mapNodes(_children)}
        </StyledTreeItem>
      );
    });
  };

  return (
    <>
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        setExpanded={setExpanded}
      />
      <Divider />
      <Box sx={{ overflow: 'auto', scrollbarWidth: 'thin' }}>
        <SimpleTreeView
          slots={{ collapseIcon: CollapseIcon, expandIcon: ExpandIcon }}
          expandedItems={expanded}
          onExpandedItemsChange={handleExpandedItemsChange}
          selectedItems={selected}>
          {mapNodes(treeNodes)}
        </SimpleTreeView>
      </Box>
    </>
  );
}
