import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import first from 'lodash/first';

import useTheme from '@mui/material/styles/useTheme';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ChangePasswordForm from './ChangePasswordForm';
import APIInfo from './APIInfo';
import AlarmsConfigurationTable from '../../../components/table/tables/AlarmsConfigurationTable';
import ReportSubscriptionsTable from './ReportSubscriptionsTable';
import TabBar from '../../../components/tab/TabBar';
import UserMembershipsList from './UserMembershipsList';
import useTab from '../../../store/hooks/useTab';

const API = 'api';
const MEMBERSHIPS = 'memberships';
const ALARMS = 'alarms';
const CHANGE_PASSWORD = 'change-password';
const REPORTS = 'reports';
const TABS = [MEMBERSHIPS, ALARMS, CHANGE_PASSWORD];

const TabContainer = ({ children }) => {
  const theme = useTheme();
  const { item: user } = useSelector((state) => state.user);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      sx={{ maxWidth: '100%', px: 1, mb: 6, mt: 1 }}>
      <Grid size={{ xs: 12 }} display='flex' justifyContent='center' my={1}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-around'
          sx={{ width: '100%', maxWidth: 400 }}>
          <Avatar sx={{ bgcolor: theme.veregy_colors.grey }}>
            {first(get(user, 'name'))}
          </Avatar>
          <Typography variant='body1' color='textSecondary'>
            {user?.name}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {user?.email}
          </Typography>
        </Stack>
      </Grid>
      {children}
    </Grid>
  );
};

export default function Profile() {
  const organizations = useSelector((state) => state.organizations.data);
  const { item: user, memberships } = useSelector((state) => state.user);

  const [tabs, setTabs] = useState([MEMBERSHIPS, ALARMS, CHANGE_PASSWORD]);
  const selectedTab = useTab(tabs);

  useEffect(() => {
    let _tabs = [...TABS];
    if (user && 'api' in user && !_tabs.includes(API)) {
      _tabs.push(API);
    }
    if (!_tabs.includes(REPORTS)) {
      _tabs.push(REPORTS);
    }
    setTabs(_tabs);
  }, [user, memberships, organizations]);

  return (
    <TabBar tabs={tabs} selectedTab={selectedTab}>
      <TabContainer tab={MEMBERSHIPS}>
        <UserMembershipsList />
      </TabContainer>
      <TabContainer tab={ALARMS}>
        <AlarmsConfigurationTable
          resource={user}
          orgSelect={true}
          typeSelect={true}
        />
      </TabContainer>
      <TabContainer tab={CHANGE_PASSWORD}>
        <ChangePasswordForm />
      </TabContainer>
      <TabContainer tab={API}>
        <APIInfo />
      </TabContainer>
      <TabContainer tab={REPORTS}>
        <ReportSubscriptionsTable />
      </TabContainer>
    </TabBar>
  );
}
