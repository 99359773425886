import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

/** MIDDLEWARE **/
import { thunk as thunkMiddleware } from 'redux-thunk';

/** REDUCERS **/
import admin from './admin';
import alarms from './alarms';
import app from './app';
import dialogs from './dialogs';
import images from './images';
import inverters from './inverters';
import kiosk from './kiosk';
import licenses from './licenses';
import loggers from './loggers';
import memberships from './memberships';
import meters from './meters';
import nodes from './nodes';
import organizations from './organizations';
import pages from './pages';
import reports from './reports';
import sensors from './sensors';
import sites from './sites';
import timeseries from './timeseries';
import user from './user';
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: combineReducers({
    admin,
    alarms,
    app,
    dialogs,
    images,
    inverters,
    kiosk,
    licenses,
    loggers,
    memberships,
    meters,
    nodes,
    organizations,
    pages,
    reports,
    router: routerReducer,
    sensors,
    sites,
    timeseries,
    user,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(routerMiddleware, thunkMiddleware),
});

export const history = createReduxHistory(store);
