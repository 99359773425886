import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import VeregyIcon from './VeregyIcon';

export default function HowItWorks() {
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        backgroundImage: (theme) => theme.vars.overlays[8],
        boxShadow: (theme) => theme.shadows[4],
        borderRadius: 1,
        px: 4,
        py: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
      }}>
      <VeregyIcon
        name='Instructions'
        sx={{ ml: 2, mr: 2, alignSelf: 'center' }}
      />
      <Box>
        <Typography variant='h5' align='left' sx={{ pb: 1 }} fontWeight='bold'>
          How It Works
        </Typography>
        <Typography variant='body1' align='left'>
          LightLevel harnesses advanced technology to monitor every aspect of
          your solar array's performance. Our intuitive interface gathers data
          from weather patterns, equipment diagnostics, and production metrics
          to provide you with comprehensive insights into your solar assets.
        </Typography>
      </Box>
    </Box>
  );
}
