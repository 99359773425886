import { createAsyncThunk } from '@reduxjs/toolkit';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { toastr } from '../../components/CustomToast';

const getSites = createAsyncThunk(
  'sites/getSites',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().sites;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const sites = await new WebAPIClient().GET('/resource/sites');
    return { data: sites };
  }
);

const putSite = createAsyncThunk(
  'sites/putSite',
  async (site, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: sites } = getState().sites;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedSite = await new WebAPIClient().PUT(
        `/resource/sites/${site.org_id}/${site.site_id}`,
        site
      );

      let _sites = cloneDeep(sites);
      remove(_sites, { site_id: get(updatedSite, 'site_id') });
      toastr.success({ title: 'Site updated' });
      return { data: concat(_sites, updatedSite) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getSites, putSite };
