import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import get from 'lodash/get';

import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import WidgetLoader from '../../../../components/widgets/Loader';
import ClipboardField from '../../../../components/ClipboardField';

const DAYJS_FORMAT = 'YYYY MMM Do h:mm A z';

const getTimestampDjs = (timestamp) => {
  if (Number(timestamp) > 0 && Number(timestamp) < 10000000000) {
    return dayjs.unix(Number(timestamp));
  } else {
    return dayjs.utc(timestamp);
  }
};

function LoggerInfo(props) {
  const { site, logger, loading } = props;
  const theme = useTheme();
  const ipAddress = get(logger, 'ip_address', '');
  const [lastOk, setLastOk] = useState(null);
  const [lastFail, setLastFail] = useState(null);

  useEffect(() => {
    const _lastOk = getTimestampDjs(get(logger, 'stats.last_ok_time'));
    const _lastFail = getTimestampDjs(get(logger, 'stats.last_fail_time'));

    if (!site?.timezone?.zone) {
      setLastOk(_lastOk.local());
      setLastFail(_lastFail.local());
    } else {
      setLastOk(_lastOk.tz(site?.timezone?.zone).local());
      if (_lastFail.year() < 2015) {
        setLastFail('-');
      } else {
        setLastFail(_lastFail.tz(site?.timezone?.zone).local());
      }
    }
  }, [logger, site]);

  const online = get(logger, 'online', false);
  const color = (() => {
    if (!loading) {
      if (online) {
        return theme.veregy_colors.green;
      } else return theme.veregy_colors.red;
    } else return theme.veregy_colors.grey;
  })();

  const lastUpdate = (() => {
    if (lastOk?.isValid() || lastFail?.isValid()) {
      return (online ? lastOk : lastFail).format(DAYJS_FORMAT);
    }
    return '';
  })();

  return (
    <Card
      raised
      sx={{
        height: '100%',
        borderBottom: (theme) => `10px solid ${theme.veregy_colors.grey}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        pb: 0.5,
        px: 1,
        pt: 2,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: 2,
          gap: 1,
        }}>
        <TextField
          id='last-update'
          label={`Last ${online ? 'OK' : 'Fail'} Time`}
          value={lastUpdate}
          fullWidth
          variant='standard'
          slotProps={{
            input: {
              readOnly: true,
              disableUnderline: true,
              fullWidth: true,
            },
          }}
        />
        <ClipboardField
          id='kiosk-url'
          label='IP Address'
          value={ipAddress}
          clipboardPosition='start'
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip
            title={get(logger, 'online', false) ? 'online' : 'offline'}
            placement='left'>
            <Icon
              sx={{
                color,
                mr: 1,
                fontSize: '1.5rem',
                height: 'inherit',
                width: 'inherit',
              }}>
              <div
                style={{
                  backgroundColor: color,
                  borderRadius: '50%',
                  width: '1.3rem',
                  height: '1.3rem',
                }}
              />
            </Icon>
          </Tooltip>
          <Typography
            align='left'
            variant='h5'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}>
            {logger?.name}
          </Typography>
        </Box>
        {loading && <WidgetLoader />}
      </Box>
    </Card>
  );
}

LoggerInfo.propTypes = {
  logger: PropTypes.object,
  loading: PropTypes.bool,
};

export default LoggerInfo;
