import dayjs from 'dayjs';

import each from 'lodash/each';
import get from 'lodash/get';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import min from 'lodash/min';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import mean from 'lodash/mean';
import { default as _range } from 'lodash/range';
import remove from 'lodash/remove';
import set from 'lodash/set';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import has from 'lodash/has';
import isNull from 'lodash/isNull';
import sum from 'lodash/sum';
import maxBy from 'lodash/maxBy';
import replace from 'lodash/replace';

const ORG_IDS_WITH_CLIPPED_PRODUCTION = [
  'org:fc178b97-f98f-43fc-914f-95c356c4d123',
];

export const prepareMeterChartData = (payload, range) => {
  let _payload = cloneDeep(payload);
  let timeseries = _range(range.start.unix(), range.end.unix() + 1, 900);
  let meterId = get(payload, 'meter_id');

  timeseries = map(timeseries, (timestamp) => {
    let timeData = { timestamp };
    let records = get(_payload, 'records');
    let meterTimeData = remove(records, { timestamp });
    if (meterTimeData.length > 0) {
      set(timeData, meterId, get(meterTimeData, '0.value'));
    }
    if (Object.keys(timeData).length === 1) {
      timeData = { timestamp };
    }
    return timeData;
  });

  return compact(timeseries);
};

export const prepareInverterChartData = (payload, range) => {
  let _payload = cloneDeep(payload);
  let timeseries = _range(range.start.unix(), range.end.unix() + 1, 900);
  let inverterId = get(payload, 'inverter_id');

  timeseries = map(timeseries, (timestamp) => {
    let timeData = { timestamp };
    let records = get(_payload, 'records');
    let inverterTimeData = remove(records, { timestamp });
    if (inverterTimeData.length > 0) {
      set(timeData, inverterId, get(inverterTimeData, '0.value'));
    }
    if (Object.keys(timeData).length === 1) {
      timeData = { timestamp };
    }
    return timeData;
  });

  return compact(timeseries);
};

export const prepareSensorTimeseriesData = (payload, range) => {
  let _payload = cloneDeep(payload);
  let timeseries = _range(range.start.unix(), range.end.unix() + 1, 900);
  let sensorId = get(payload, 'sensor_id');

  timeseries = map(timeseries, (timestamp) => {
    let timeData = { timestamp };
    let records = get(_payload, 'records');
    let sensorTimeData = remove(records, { timestamp });

    if (sensorTimeData.length > 0) {
      each(sensorTimeData, (datapoint) => {
        set(
          timeData,
          `${sensorId}:${replace(get(datapoint, 'measure_name'), /_.*/, '')}`,
          get(datapoint, 'value')
        );
      });
    }

    if (Object.keys(timeData).length === 1) {
      timeData = { timestamp };
    }
    return timeData;
  });

  return compact(timeseries);
};

export const prepareEstimatedProductionTimeseriesData = (
  rawData,
  range,
  sensors,
  inverterEfficiency,
  systemLosses,
  tempCoefficient
) => {
  let data = cloneDeep(rawData);
  let timestamps = _range(range.start.unix(), range.end.unix() + 1, 900);

  let metersData = filter(data, (resource) => {
    return has(resource, 'meter_id');
  });

  let sensorsData = filter(data, (resource) => {
    return has(resource, 'sensor_id');
  });

  let res = map(timestamps, (timestamp) => {
    // console.log('timestamp: ', timestamp);
    let production = null;
    let outerRadiationValues = [];
    let outerTempValues = [];
    let estimatedACPowerValues = [];

    // compile meter data
    each(metersData, (meter) => {
      let records = get(meter, 'records');
      let pointTime = get(records, '0.timestamp');

      while (
        pointTime >= timestamp &&
        pointTime < timestamp + 900 // = 1
      ) {
        let dataPoint = records.shift();

        let _production = get(dataPoint, 'value');

        if (_production && isNull(production)) {
          production = 0;
        }
        production += _production;
        pointTime = get(records, '0.timestamp');
      }
    });

    // compile sensor data
    each(sensorsData, (sensorData) => {
      let innerRadiationValues = [];
      let innerTempValues = [];
      let sensor = find(sensors, {
        sensor_id: get(sensorData, 'sensor_id'),
      });

      let records = get(sensorData, 'records');
      let pointTime = get(records, '0.timestamp');

      while (pointTime >= timestamp && pointTime < timestamp + 900) {
        const timeRecords = remove(records, { timestamp: pointTime });
        const tempRecord = find(timeRecords, (record) =>
          record.measure_name.startsWith('PanelTemp')
        );
        const radiationRecord = find(timeRecords, (record) =>
          record.measure_name.startsWith('Radiation')
        );
        if (tempRecord) {
          innerTempValues.push(tempRecord.value);
          outerTempValues.push(tempRecord.value);
        }
        if (radiationRecord) {
          innerRadiationValues.push(radiationRecord.value);
          outerRadiationValues.push(radiationRecord.value);
        }
        pointTime = get(records, '0.timestamp');
      }

      if (innerTempValues.length === 0 || innerRadiationValues.length === 0) {
        return null;
      }

      estimatedACPowerValues.push(
        min([
          estimateACPower(
            mean(innerRadiationValues),
            mean(innerTempValues),
            get(sensor, 'DCSize'),
            inverterEfficiency,
            systemLosses,
            tempCoefficient
          ),
          get(sensor, 'ACSize'),
        ])
      );
    });

    return {
      timestamp: timestamp,
      production: !isNull(production) ? production : null,
      radiation:
        outerRadiationValues.length > 0 ? mean(outerRadiationValues) : null,
      temp: outerTempValues.length > 0 ? mean(outerTempValues) : null,
      powerEstimate:
        estimatedACPowerValues.length > 0 ? sum(estimatedACPowerValues) : null,
    };
  });
  return res;
};

export const adjustTimeseriesUnits = (data, units) => {
  const multiplier = {
    W: 1000,
    kW: 1,
    MW: 0.001,
    Wh: 250,
    kWh: 0.25,
    MWh: 0.00025,
  }[units];

  return map(data, (point) => {
    return merge(
      ...map(point, (value, key) => {
        if (key !== 'timestamp') {
          value = value * multiplier;
        }
        return {
          [key]: value,
        };
      })
    );
  });
};

/**
 *
 * @param {Number} irradiance POA Irradiance
 * @param {Number} temp Panel Temperature
 * @param {Number} dCSize DC Size
 * @param {Number} efficiency Inverter efficieny
 * @param {Number} losses System Losses
 * @param {Number} coefficient Temperature coefficient
 */
export const estimateACPower = (
  irradiance,
  temp,
  dCSize = 1,
  efficiency = 0.96,
  losses = 0.045,
  coefficient = -0.004
) => {
  return (
    efficiency *
    (1 - losses) *
    (irradiance / 1000) *
    dCSize *
    (1 + coefficient * (temp - 25))
  );
};

export const currentGeneration = (timeseriesData) => {
  let dayJsObj = dayjs().subtract(30, 'minute');
  return reduce(
    timeseriesData,
    (acc, timeseries) => {
      const mostRecentDataPoint = maxBy(timeseries.data, 'timestamp');
      if (mostRecentDataPoint?.timestamp > dayJsObj.unix()) {
        return acc + mostRecentDataPoint.value;
      }
      return acc;
    },
    0
  );
};

export const addEstimatedProductionToChartData = (
  payload,
  estimatedData,
  zone
) => {
  let today = null;
  if (zone) {
    today = dayjs().tz(zone).startOf('day');
  } else {
    today = dayjs().startOf('day');
  }

  return map(payload, (datapoint) => {
    let datetime = dayjs.unix(get(datapoint, 'timestamp'));
    if (zone) {
      datetime = datetime.tz(zone);
    }
    const hour = datetime.hour();
    const minute = datetime.minute();

    if (datetime.isSame(today, 'day')) {
      each(estimatedData, (item) => {
        if (!has(datapoint, item?.device_id)) {
          let value = get(
            find(item?.data, (record) => {
              return (
                get(record, 'hour') === hour && get(record, 'minute') === minute
              );
            }),
            'value'
          );
          datapoint[`${item?.device_id}:estimated`] = value;
        }
      });
    }
    return datapoint;
  });
};

export const generateTicks = (range) => {
  if (!range.start || !range.end) return [];

  if (range.end.unix() - range.start.unix() < 90000) {
    // return 6am, 12pm and 6 pm
    return [
      range.start.hour(6).minute(0).unix(),
      range.start.hour(12).minute(0).unix(),
      range.start.hour(18).minute(0).unix(),
    ];
  } else {
    // return 12pm for each day in the range
    let ticks = [];
    let currentDate = range.start.hour(12).minute(0);
    while (currentDate <= range.end) {
      ticks.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
    return ticks.map((tick) => tick.unix());
  }
};

const sumByType = (chartData, type) =>
  reduce(
    chartData,
    (acc, point) =>
      acc +
      reduce(
        point,
        (acc, val, key) => {
          if (key === type) {
            return acc + val * 0.25;
          }
          return acc;
        },
        0
      ),
    0
  );

export const calculateTotals = (chartData) => {
  return {
    actual: sumByType(chartData, 'production'),
    estimated: sumByType(chartData, 'powerEstimate'),
    irradiance: sumByType(chartData, 'radiation'),
  };
};

export const clipProductionData = (value, orgId) => {
  if (isNaN(value)) return null;
  if (ORG_IDS_WITH_CLIPPED_PRODUCTION.includes(orgId) && value < 0) {
    return 0;
  }
  return value;
};
