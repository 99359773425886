import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {
  INVERTER,
  LOGGER,
  METER,
  SENSOR,
  SITE,
} from '../../../constants/resoureceTypes';

const TYPES = [SITE, LOGGER, METER, SENSOR, INVERTER];
function SelectDeviceType(props) {
  const { selectedType, setSelectedType } = props;

  const [type, setType] = useState('');

  useEffect(() => {
    setType(selectedType);
  }, [selectedType]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedType(e.target.value);
  };

  return (
    <Card sx={{ width: '100%', maxWidth: 250 }}>
      <FormControl variant='filled' size='small' sx={{ width: '100%' }}>
        <InputLabel size='small' id='type-label'>
          Device Type
        </InputLabel>
        <Select
          label='Device Type'
          labelId='type-label'
          id='type-select'
          disableUnderline
          value={type}
          onChange={handleSelect}
          sx={{
            backgroundColor: 'unset',
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
              sx: { '& .MuiPaper-root': { minWidth: 'unset' } },
            },
            slotProps: { paper: { sx: { minWidth: 'unset !important' } } },
          }}>
          <MenuItem id='empty-menu-item' value=''>
            <ListItemText
              primary='Show All'
              slotProps={{
                primary: {
                  variant: 'body1',
                  color: 'textSecondary',
                },
              }}
            />
          </MenuItem>
          {TYPES.map((type) => (
            <MenuItem
              key={type.name}
              id={`${type}-menu-item`}
              value={type.name}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <ListItemIcon
                sx={{
                  minWidth: 'unset !important',
                  width: 15,
                  height: 15,
                  mr: 2,
                }}>
                <FontAwesomeIcon icon={['fal', type.icon]} />
              </ListItemIcon>
              <ListItemText
                primary={type.name}
                slotProps={{
                  primary: {
                    variant: 'body1',
                    color: 'textSecondary',
                    align: 'center',
                  },
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Card>
  );
}

SelectDeviceType.propTypes = {
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
};

export default SelectDeviceType;
