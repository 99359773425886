import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import includes from 'lodash/includes';
import map from 'lodash/map';
import remove from 'lodash/remove';

import { user as initialState } from '../initialState';
import ROUTES from '../../constants/routes';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { showLoading, hideLoading } from '../app';
import { getOrganizations } from '../organizations/_organizations';
import { setLoader } from '../pages';
import { removeTokens } from '../../helpers/auth/tokens';
import { toastr } from '../../components/CustomToast';

const authenticateUser = createAsyncThunk(
  'user/authenticateUser',
  async (_, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const { user, memberships } = await new WebAPIClient().GET(
        '/resource/auth'
      );
      const subscriptions = await new WebAPIClient().GET(
        `/resource/subscriptions/${user.user_id}`
      );
      dispatch(getOrganizations(user));

      return { item: user, memberships, subscriptions };
    } catch (err) {
      errorResponseToastr(err);
      dispatch(push(ROUTES.UNAUTH.LOGIN));
      dispatch(setLoader(false));
    }
  }
);

const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { dispatch }) => {
    try {
      setLoader(true);
      removeTokens();
      return initialState;
    } catch (err) {
      console.error('LOGOUT FAILED', err);
      errorResponseToastr(err);
    } finally {
      dispatch(push(ROUTES.UNAUTH.LOGIN));
      setLoader(false);
    }
  }
);

const createSubscriptions = createAsyncThunk(
  'user/createSubscriptions',
  async (newSubscriptions, { dispatch, getState }) => {
    const { subscriptions } = getState().user;
    let subscriptionsAdded = [];
    try {
      dispatch(showLoading());
      let _subscriptions = cloneDeep(subscriptions);

      subscriptionsAdded = await Promise.all(
        map(
          newSubscriptions,
          async (sub) =>
            await new WebAPIClient().POST(
              `/resource/subscriptions/${sub.userId}`,
              {
                orgId: sub.orgId,
                deviceId: sub.deviceId,
                triggerId: sub.triggerId,
              }
            )
        )
      );

      toastr.success({ title: 'Alarm Subscriptions created' });
      return {
        subscriptions: concat(_subscriptions, subscriptionsAdded),
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSubscriptions = createAsyncThunk(
  'user/deleteSubscriptions',
  async (removeSubscriptions, { dispatch, getState }) => {
    const { subscriptions } = getState().user;
    let subscriptionsRemoved = [];
    try {
      dispatch(showLoading());
      let _subscriptions = cloneDeep(subscriptions);

      subscriptionsRemoved = await Promise.all(
        map(
          removeSubscriptions,
          async (subscription) =>
            await new WebAPIClient().DELETE(
              `/resource/subscriptions/${subscription.subscription_id}`
            )
        )
      );

      // update store
      remove(_subscriptions, (subscription) =>
        includes(subscriptionsRemoved, subscription.subscription_id)
      );

      toastr.success({ title: 'Alarm Subscriptions deleted' });
      return {
        subscriptions: _subscriptions,
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  authenticateUser,
  createSubscriptions,
  deleteSubscriptions,
  logoutUser,
};
