import React, { useState } from 'react';
import dayjs from 'dayjs';
import concat from 'lodash/concat';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import WebAPIClient, { errorResponseToastr } from '../../api';
import WidgetLoader from '../widgets/Loader';

function RegisterDataGrid(props) {
  const { registerObjects, orgId, deviceId, setData, children } = props;

  const [loading, setLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(null);

  const handleFetchLatest = async () => {
    const end = dayjs();
    const start = end.subtract(30, 'minute');

    const analogObjectNames = Object.values(registerObjects.analogValue).map(
      (obj) => obj.objectName
    );
    const binaryObjectNames = Object.values(
      registerObjects?.binaryValue || {}
    ).map((obj) => obj.objectName);
    const multiStateObjectNames = Object.values(
      registerObjects.multiStateValue
    ).map((obj) => obj.objectName);

    const attributes = concat(
      analogObjectNames,
      binaryObjectNames,
      multiStateObjectNames
    );

    setLoading(true);

    try {
      const payload = await new WebAPIClient().GET(
        `/resource/timestream-device/${orgId}/${start.unix() * 1000}/${
          end.unix() * 1000
        }`,
        { attributes: JSON.stringify(attributes), deviceId }
      );
      if (payload.length > 0) {
        setData(payload[payload.length - 1].values);
        setTimestamp(payload[payload.length - 1].timestamp);
      } else {
        setData([]);
        setTimestamp(null);
      }
    } catch (error) {
      errorResponseToastr(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        size={{ xs: 12 }}
        display='flex'
        justifyContent='center'
        alignItems='center'>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {loading ? (
            <WidgetLoader delay={0} />
          ) : (
            <Tooltip
              title='fetch latest'
              placement='top'
              onClick={handleFetchLatest}>
              <IconButton sx={{ height: 25, width: 25 }}>
                <FontAwesomeIcon size='sm' icon={['fal', 'cloud-download']} />
              </IconButton>
            </Tooltip>
          )}
          <Typography
            sx={{ minWidth: 100 }}
            variant='body1'
            color='textSecondary'>
            {timestamp
              ? dayjs.unix(timestamp).format('MMMM Do HH:mm')
              : 'Fetch latest device data'}
          </Typography>
        </Box>
      </Grid>
      {children}
    </>
  );
}

export default RegisterDataGrid;
