import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { toastr } from '../../components/CustomToast';

const getSensors = createAsyncThunk(
  'sensors/getSensors',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().sensors;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const sensors = await new WebAPIClient().GET('/resource/sensors');
    return { data: sensors };
  }
);

const putSensor = createAsyncThunk(
  'sensors/putSensor',
  async (sensor, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: sensors } = getState().sensors;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedSensor = await new WebAPIClient().PUT(
        `/resource/sensors/${sensor.org_id}/${sensor.sensor_id}`,
        sensor
      );

      let _sensors = cloneDeep(sensors);
      remove(_sensors, { sensor_id: get(updatedSensor, 'sensor_id') });
      _sensors = concat(_sensors, updatedSensor);

      toastr.success({ title: 'Sensor updated' });
      return { data: _sensors };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getSensors, putSensor };
