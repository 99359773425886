import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

import Grid from '@mui/material/Grid2';

import ROLES from '../../../../constants/roles';
import { setPage } from '../../../../store/pages';
import useSite from '../../../../store/hooks/useSite';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';

import CountGrid from '../../../../components/Grid/CountGrid';
import ResourceCount from '../../../../components/widgets/ResourceCount';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ProductionIndex from '../../../../components/widgets/ProductionIndex';
import LoggersGrid from './LoggersGrid';
import ViewStack from '../../../../components/ViewStack';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import useView from '../../../../store/hooks/useView';

const LOGGERS = 'loggers';
const ALARMS = 'alarms';
const VIEWS = [LOGGERS, ALARMS];

function SiteDashboard() {
  const dispatch = useDispatch();
  const selectedView = useView(VIEWS);

  const { id, expectationType } = useSelector((state) => state.pages.site);
  const site = useSite(id);
  const { loggers, meters, inverters, sensors, alarms, loading } =
    useSiteDevices(id);
  const isOrgAdmin = useVerifyOrgRole(site?.org_id, ROLES.ADMIN.value);
  const [loggerIds, setLoggerIds] = useState([]);

  useEffect(() => {
    setLoggerIds(sortBy(loggers, 'name').map((logger) => logger.logger_id));
  }, [loggers]);

  const handleSetExpectationType = (type) => {
    switch (type) {
      case 'modeled':
      case 'corrected':
        dispatch(setPage({ page: 'site', expectationType: type }));
        break;

      default:
        dispatch(setPage({ page: 'site', expectationType: 'modeled' }));
        break;
    }
  };

  const activeAlarms = filter(alarms, { status: true });
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: '100%', pt: 1, px: 1, mb: 6 }}>
      <Grid size={{ xs: 12, md: 4 }}>
        <ResourceCount loading={loading}>
          <CountGrid number={meters.length} name='Meters' />
          <CountGrid number={inverters.length} name='Inverters' />
          <CountGrid number={sensors.length} name='Sensors' />
          <CountGrid number={activeAlarms.length} name='Alarms' />
        </ResourceCount>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <CurrentGeneration
          loggers={loggers}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <ProductionIndex
          sites={[site]}
          meters={meters}
          inverters={inverters}
          sensors={sensors}
          loading={loading}
          expectationType={expectationType}
          setExpectationType={handleSetExpectationType}
          disabled={!isOrgAdmin}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ViewStack selectedView={selectedView} views={VIEWS} />
      </Grid>
      {selectedView === LOGGERS && (
        <LoggersGrid loading={loading} loggerIds={loggerIds} site={site} />
      )}
      {selectedView === ALARMS && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={site} alarms={alarms} />
        </Grid>
      )}
    </Grid>
  );
}

export default SiteDashboard;
