import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { user as initialState } from '../initialState';

import {
  authenticateUser,
  logoutUser,
  createSubscriptions,
  deleteSubscriptions,
} from './_users';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      authenticateUser,
      logoutUser,
      createSubscriptions,
      deleteSubscriptions,
    ]);
  },
});

// Export the reducer, either as a default or named export
export {
  authenticateUser,
  logoutUser,
  createSubscriptions,
  deleteSubscriptions,
};
export default reducer;
