import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { displaykW } from '../../../../helpers/display-energy';

function WeatherStationChartTotals(props) {
  const { rawData, selectedMeters, selectedSensors } = props;

  const [production, setProduction] = useState(0);
  const [irradiance, setIrradiance] = useState(0);

  useEffect(() => {
    const _production = reduce(
      rawData,
      (acc, device) => {
        if (device.device_id.startsWith('meter')) {
          return (
            acc +
            reduce(
              device.records,
              (acc, record) => {
                return acc + get(record, 'value', 0) / 4;
              },
              0
            )
          );
        } else {
          return acc;
        }
      },
      0
    );
    const _irradiance = reduce(
      rawData,
      (acc, device) => {
        if (device.device_id.startsWith('sensor')) {
          // sum
          return (
            acc +
            reduce(
              device.records,
              (acc, record) => {
                return acc + get(record, 'value', 0) / 4;
              },
              0
            )
          );
        } else {
          return acc;
        }
      },
      0
    );

    setProduction(_production);
    setIrradiance(_irradiance);
  }, [rawData]);

  return (
    <List sx={{ width: '100%', maxWidth: 350 }}>
      {selectedMeters.length > 0 && (
        <ListItem>
          <ListItemText secondary='Total Production: ' />
          <ListItemText
            primary={displaykW(production, true)}
            slotProps={{
              primary: {
                fontWeight: 'bold',
                align: 'right',
                sx: { color: (theme) => theme.veregy_colors.blue },
              },
            }}
          />
        </ListItem>
      )}
      {selectedSensors.length > 0 && (
        <ListItem>
          <ListItemText secondary='Total Irradiance: ' />
          <ListItemText
            primary={displaykW(irradiance, true)}
            slotProps={{
              primary: {
                fontWeight: 'bold',
                align: 'right',
                sx: { color: (theme) => theme.veregy_colors.orange },
              },
            }}
          />
        </ListItem>
      )}
    </List>
  );
}

WeatherStationChartTotals.propTypes = {
  rawData: PropTypes.array,
  selectedMeters: PropTypes.array,
  selectedSensors: PropTypes.array,
};

export default WeatherStationChartTotals;
