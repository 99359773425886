import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import find from 'lodash/find';

import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  Bar,
  BarChart,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { toastr } from '../../../CustomToast';
import Loader from '../../../Loaders/ComponentLoader';
import ChartTooltip from './Tooltip';

export const TYPES = ['individual', 'sum'];

const prepareAcutalValues = (sites, meters, year, month) => {
  return reduce(
    sites,
    (acc, site) => {
      let siteMeters = filter(meters, { site_id: site.site_id });

      return {
        ...acc,
        [`${site.site_id}-actual`]: reduce(
          siteMeters,
          (acc, meter) => {
            let monthRecordedProduction = find(
              get(meter, 'energy_stats.production', []),
              {
                year,
                month,
              }
            );
            let monthAdjustedProduction = find(
              get(meter, 'energy_stats.adjusted_production', []),
              {
                year,
                month,
              }
            );
            return (
              acc +
              get(monthRecordedProduction, 'value', 0) +
              get(monthAdjustedProduction, 'value', 0)
            );
          },
          0
        ),
      };
    },
    {}
  );
};

const prepareExpectedValues = (sites, year, month) => {
  return reduce(
    sites,
    (acc, site) => {
      return {
        ...acc,
        [`${site.site_id}-expected`]: get(
          find(get(site, 'modeled_expectations', []), {
            year,
            month,
          }),
          'value',
          0
        ),
      };
    },
    {}
  );
};

const prepareWeatherCorrectedPerformanceData = (sites, year, month) => {
  return reduce(
    sites,
    (acc, site) => {
      let monthData = find(get(site, 'wc_expectations', []), {
        year,
        month,
      });

      return {
        ...acc,
        [`${site.site_id}-actual`]: get(monthData, 'actual', 0),
        [`${site.site_id}-expected`]: get(monthData, 'expected', 0),
      };
    },
    {}
  );
};

const ChartLegend = (props) => {
  const { blue, orange } = props;
  return (
    <Stack direction='row' sx={{ ml: 20 }} spacing={3}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box sx={{ width: 10, height: 10, backgroundColor: blue, mr: 1 }} />
        <Typography variant='body2'>Actual</Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box sx={{ width: 10, height: 10, backgroundColor: orange, mr: 1 }} />
        <Typography variant='body2'>Expected</Typography>
      </div>
    </Stack>
  );
};

function PerformanceChart({
  sites,
  meters,
  range,
  chartData,
  setRawData,
  chartType,
  dataType,
  unit,
  showLegend,
  setSelectedMonthData,
}) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [focusArea, setFocusArea] = useState(null);

  useEffect(() => {
    if (!isEmpty(sites)) {
      setLoading(true);
      let _chartData = [];
      let date = dayjs(range.start);

      while (date.isBefore(dayjs(range.end).add(1, 'day'))) {
        let year = date.year();
        let month = date.month() + 1;
        let performance = {};

        if (dataType === 'modeled') {
          performance = {
            ...prepareAcutalValues(sites, meters, year, month),
            ...prepareExpectedValues(sites, year, month),
          };
        } else {
          performance = prepareWeatherCorrectedPerformanceData(
            sites,
            year,
            month
          );
        }

        _chartData.push({
          timestamp: date.valueOf(),
          ...performance,
        });

        date = date.add(1, 'month');
      }
      setRawData(_chartData);
      setLoading(false);
    } else {
      toastr.warning({ title: 'Select at least one site' });
    }
  }, [range, sites, meters, dataType, setRawData]);

  const chartColor = (baseColor, idx) => {
    const size = sites.length;

    let match = baseColor.match(
      /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/
    );
    const red = match[1];
    const green = match[2];
    const blue = match[3];

    let opacityIncrement = 0.15;
    if (size > 9) {
      opacityIncrement = 1 / (size + 2);
    }

    return `rgba(${red}, ${green}, ${blue}, ${1 - opacityIncrement * idx})`;
  };

  const renderBars = () => {
    if (chartType === 'sum') {
      return (
        <>
          <Bar
            barSize={10}
            key={'actual'}
            type='monotone'
            dataKey={'actual'}
            fill={theme.veregy_colors.blue}
          />
          <Bar
            barSize={10}
            key={'expected'}
            type='monotone'
            dataKey={'expected'}
            fill={theme.veregy_colors.orange}
          />
        </>
      );
    } else {
      return (
        <>
          {map(sites, (site, idx) => {
            let colorA = chartColor(theme.veregy_colors.blue, idx);
            let colorE = chartColor(theme.veregy_colors.orange, idx);
            return (
              <Fragment key={site.site_id}>
                <Bar
                  stackId='actual'
                  barSize={10}
                  key={`${site.site_id}-actual`}
                  type='monotone'
                  dataKey={`${site.site_id}-actual`}
                  fill={colorA}
                />
                <Bar
                  stackId='expected'
                  barSize={10}
                  key={`${site.site_id}-expected`}
                  type='monotone'
                  dataKey={`${site.site_id}-expected`}
                  fill={colorE}
                />
              </Fragment>
            );
          })}
        </>
      );
    }
  };

  if (loading)
    return (
      <div
        style={{
          height: '450px',
        }}>
        <Loader height={150} width={150} />
      </div>
    );

  return (
    <ResponsiveContainer
      key={`performance-chart-responsive-container`}
      width='100%'
      height={450}
      style={{ marginBottom: theme.spacing(2) }}>
      <BarChart
        onClick={(e) => {
          setSelectedMonthData(e);
          if (e?.activeTooltipIndex) {
            setFocusArea(e.activeTooltipIndex);
          } else {
            setFocusArea(null);
          }
        }}
        width='100%'
        height={450}
        data={chartData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}>
        {showLegend && (
          <Legend
            verticalAlign='right'
            content={
              <ChartLegend
                blue={theme.veregy_colors.blue}
                orange={theme.veregy_colors.orange}
              />
            }
            wrapperStyle={{
              position: 'relative',
              top: -455,
              height: 0,
            }}
          />
        )}
        <YAxis
          width={75}
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
          unit={unit}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ' ';
          }}
        />
        <XAxis
          dataKey='timestamp'
          tickFormatter={(unixTime) => {
            return dayjs(unixTime).format('YYYY MMM');
          }}
        />
        <Tooltip content={<ChartTooltip unit={unit} />} cursor={false} />
        {focusArea && (
          <ReferenceArea
            x1={chartData[focusArea]?.timestamp}
            x2={chartData[focusArea]?.timestamp}
          />
        )}
        {renderBars()}
      </BarChart>
    </ResponsiveContainer>
  );
}

PerformanceChart.propTypes = {
  chartData: PropTypes.array,
  sites: PropTypes.array,
  meters: PropTypes.array,
  range: PropTypes.object,
  setRawData: PropTypes.func,
  chartType: PropTypes.string,
  dataType: PropTypes.string,
  unit: PropTypes.string,
  showLegend: PropTypes.bool,
  setSelectedMonthData: PropTypes.func,
};

export default PerformanceChart;
