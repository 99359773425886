import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';

import useMediaQuery from '@mui/material/useMediaQuery';

import { setPage } from '../../../store/pages';
import usePageId from '../../../store/hooks/usePageId';
import useOrganization from '../../../store/hooks/useOrganization';
import useTimeseriesData from '../../../store/hooks/useTimeseriesData';

import TabBar, { ANALYSIS, DASHBOARD } from '../../../components/tab/TabBar';
import ROUTES from '../../../constants/routes';
import Dashboard from './Dashboard';
import Analysis from './Anlaysis';
import Memberships from './Memberships';
import useTab from '../../../store/hooks/useTab';

const PAGE = 'portfolio';
const MEMBERSHIPS = 'memberships';
const TABS = [DASHBOARD, ANALYSIS, MEMBERSHIPS];

export default function Portfolio() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const {
    item: user,
    memberships,
    loading: userLoading,
  } = useSelector((state) => state.user);
  const organizations = useSelector((state) => state.organizations.data);
  const { id } = useSelector((state) => state.pages.portfolio);
  const { organization: portfolio, loading: orgLoading } = useOrganization(id);
  usePageId(id, PAGE);
  useTimeseriesData(id);

  const [tabs, setTabs] = useState([DASHBOARD, MEMBERSHIPS]);
  const selectedTab = useTab(tabs);

  useEffect(() => {
    if (isMobile) setTabs([DASHBOARD, MEMBERSHIPS]);
    else setTabs(TABS);
  }, [isMobile]);

  useEffect(() => {
    if (userLoading || orgLoading) return;
    const idMembership = memberships.find((m) => m.org_id === id);
    const defaultMembership = memberships.find(
      (m) => m.org_id === user?.default_organization
    );

    // if stored id not in memberships, navigate to user.default_org
    if (!idMembership && defaultMembership) {
      const defaultOrg = find(
        organizations,
        (o) => o.org_id === defaultMembership.org_id
      );
      if (defaultOrg.is_portfolio) {
        dispatch(setPage({ page: PAGE, id: defaultOrg.org_id }));
      } else {
        dispatch(push(ROUTES.AUTH.ORGANIZATION));
      }
    }
  }, [
    dispatch,
    id,
    portfolio,
    orgLoading,
    user,
    memberships,
    userLoading,
    organizations,
  ]);

  return (
    <TabBar tabs={tabs} selectedTab={selectedTab}>
      <Dashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <Memberships tab={MEMBERSHIPS} />
    </TabBar>
  );
}
