import React, { useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import map from 'lodash/map';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';

import {
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { chartColor } from '../../../styles/muiTheme';

import { generateTicks } from '../../../helpers/chart-data';
import { formatTimestamp } from '../../../helpers/date';
import Loader from '../../Loaders/ComponentLoader';
import GeneralTooltip from '../tooltips/GeneralTooltip';
import ExternalTooltip from './ExternalTooltip';

function ChartContainer({ dataKeys, timezone, range, loading, chartData }) {
  const [focusLine, setFocusLine] = useState(null);
  const [selectedPayload, setSelectedPayload] = useState(null);

  const ticks = generateTicks(range);
  return (
    <>
      <Grid size={{ xs: 12, width: '100%' }}>
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[8],
            p: 1,
            width: '100%',
          }}>
          {loading || !range.start || !range.end ? (
            <div style={{ height: '450px' }}>
              <Loader height={150} width={150} />
            </div>
          ) : (
            <ResponsiveContainer key='sensor-chart' width='100%' height={450}>
              <LineChart
                width='100%'
                height={450}
                data={chartData}
                margin={{
                  top: 5,
                  right: 5,
                  left: 0,
                  bottom: 0,
                }}
                onClick={(e) => {
                  setSelectedPayload(e);
                  if (e?.activeTooltipIndex) {
                    setFocusLine(e.activeTooltipIndex);
                  } else {
                    setFocusLine(null);
                  }
                }}>
                <YAxis
                  mirror
                  domain={[
                    (dataMin) => Math.min(0, dataMin),
                    (dataMax) => Math.ceil(dataMax / 10) * 10,
                  ]}
                  tickFormatter={(val, _axis) => {
                    if (val === 0) return '';
                    return numeral(val.toPrecision(4)).format('0,0.[000]');
                  }}
                />
                <XAxis
                  type='number'
                  tickMargin={10}
                  dataKey='timestamp'
                  domain={[range.start.unix(), range.end.unix()]}
                  ticks={ticks}
                  tickFormatter={(unixTime) => {
                    let format = 'MMM Do h:mm A';
                    if (range.end.unix() - range.start.unix() > 90000) {
                      format = 'MMM Do';
                    }
                    return formatTimestamp(unixTime, timezone, format);
                  }}
                />

                <Tooltip content={<GeneralTooltip timezone={timezone} />} />

                {map(dataKeys, (dataKey, idx) => {
                  return (
                    <Line
                      dot={false}
                      key={dataKey}
                      type='monotone'
                      dataKey={dataKey}
                      stroke={chartColor(idx)}
                      connectNulls
                    />
                  );
                })}
                {focusLine && (
                  <ReferenceLine x={chartData[focusLine]?.timestamp} />
                )}
              </LineChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Grid>
      <ExternalTooltip selectedPayload={selectedPayload} timezone={timezone} />
    </>
  );
}

ChartContainer.propTypes = {
  site: PropTypes.object,
  dataKeys: PropTypes.array,
  timezone: PropTypes.object,
  setTimezone: PropTypes.func,
  range: PropTypes.object,
  setRange: PropTypes.func,
  loading: PropTypes.bool,
  chartData: PropTypes.array,
  setChartData: PropTypes.func,
  rawData: PropTypes.array,
};

export default ChartContainer;
