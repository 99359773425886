import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Grid from '@mui/material/Grid2';

import {
  compileCorrectedPerformanceIndexes,
  compilePerformanceIndexes,
} from '../../../../helpers/performance-data';
import useSite from '../../../../store/hooks/useSite';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import ProductionIndexBar from '../../../../components/widgets/ProductionIndexBar';

function SitePerformance({ siteId, expectationType }) {
  const { meters, loading } = useSiteDevices(siteId);
  const site = useSite(siteId);
  const [indexes, setIndexes] = useState({
    thisMonth: { actual: 0, expected: 0 },
    lastMonth: { actual: 0, expected: 0 },
    trailing12Month: { actual: 0, expected: 0 },
    allTime: { actual: 0, expected: 0 },
  });

  useEffect(() => {
    if (!loading) {
      let performanceIndexes = {};
      if (expectationType === 'corrected') {
        performanceIndexes = compileCorrectedPerformanceIndexes([
          get(site, 'wc_expectations', []),
        ]);
      } else {
        performanceIndexes = compilePerformanceIndexes(meters, [
          get(site, 'modeled_expectations', []),
        ]);
      }

      setIndexes(performanceIndexes);
    }
  }, [site, meters, loading, expectationType]);

  return (
    <Grid container spacing={1}>
      <ProductionIndexBar
        actual={indexes.thisMonth.actual}
        expected={indexes.thisMonth.expected}
        label='This Month'
        loading={loading}
      />
      <ProductionIndexBar
        actual={indexes.lastMonth.actual}
        expected={indexes.lastMonth.expected}
        label='Last Month'
        loading={loading}
      />
      <ProductionIndexBar
        actual={indexes.trailing12Month.actual}
        expected={indexes.trailing12Month.expected}
        label='Trailing 12-month'
        loading={loading}
      />
      <ProductionIndexBar
        actual={indexes.allTime.actual}
        expected={indexes.allTime.expected}
        label='All Time'
        loading={loading}
      />
    </Grid>
  );
}

SitePerformance.propTypes = {
  siteId: PropTypes.string,
  expectationType: PropTypes.string,
};

export default SitePerformance;
