import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';

import Grid from '@mui/material/Grid2';

import ROLES from '../../../../constants/roles';
import { setPage } from '../../../../store/pages';
import useOrganization from '../../../../store/hooks/useOrganization';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import usePortfolioMembers from '../../../../store/hooks/usePortfolioMembers';
import usePortfolioDevices from '../../../../store/hooks/usePortfolioDevices';

import ResourceCount from '../../../../components/widgets/ResourceCount';
import CountGrid from '../../../../components/Grid/CountGrid';
import CurrentGeneration from '../../../../components/widgets/CurrentGeneration';
import ProductionIndex from '../../../../components/widgets/ProductionIndex';
import ViewStack from '../../../../components/ViewStack';
import Map from '../../../../components/Map';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import OrganizationsTable from '../../../../components/table/tables/OrganizationsTable';
import ConfigurationAlert from './ConfigurationAlert';
import useView from '../../../../store/hooks/useView';

const PAGE = 'portfolio';
const ORGANIZATIONS = 'organizations';
const MAP = 'map';
const ALARMS = 'alarms';
const VIEWS = [ORGANIZATIONS, MAP, ALARMS];

function PortfolioDashboard() {
  const dispatch = useDispatch();
  const { id, expectationType } = useSelector((state) => state.pages.portfolio);

  const { organization: portfolio, loading } = useOrganization(id);
  const { portfolioMembers } = usePortfolioMembers(id);
  const { alarms, sites, loggers, meters, inverters, sensors } =
    usePortfolioDevices(id);

  const isOrgAdmin = useVerifyOrgRole(id, ROLES.ADMIN.value);
  const selectedView = useView(VIEWS);

  const handleSetExpectationType = (type) => {
    const _type = type || type;
    dispatch(setPage({ page: PAGE, expectationType: _type }));
  };

  const activeAlarms = filter(alarms, { status: true });
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: '100%', p: 1, mb: 6 }}>
      <ConfigurationAlert
        portfolio={portfolio}
        portfolioMembers={portfolioMembers}
      />
      <Grid size={{ xs: 12, md: 4 }}>
        <ResourceCount loading={loading}>
          <CountGrid number={portfolioMembers.length} name='Organizations' />
          <CountGrid number={sites.length} name='Sites' />
          <CountGrid number={meters.length} name='Meters' />
          <CountGrid number={activeAlarms.length} name='Alarms' />
        </ResourceCount>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <CurrentGeneration
          loggers={loggers}
          meters={meters}
          inverters={inverters}
          loading={loading}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <ProductionIndex
          sites={sites}
          meters={meters}
          inverters={inverters}
          sensors={sensors}
          loading={loading}
          expectationType={expectationType}
          setExpectationType={handleSetExpectationType}
          disabled={!isOrgAdmin}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ViewStack selectedView={selectedView} views={VIEWS} />
      </Grid>

      {selectedView === ORGANIZATIONS && portfolioMembers.length > 0 && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <OrganizationsTable
            children={portfolioMembers}
            sites={sites}
            meters={meters}
            inverters={inverters}
            alarms={alarms}
            expectationType={expectationType}
          />
        </Grid>
      )}
      {selectedView === MAP && (
        <Grid size={{ xs: 12 }}>
          <Map
            center={portfolio}
            locations={portfolioMembers}
            styles={{
              height: '58vh',
              width: '100%',
            }}
          />
        </Grid>
      )}
      {selectedView === ALARMS && (
        <Grid
          size={{ xs: 12 }}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={portfolio} alarms={alarms} />
        </Grid>
      )}
    </Grid>
  );
}

export default PortfolioDashboard;
