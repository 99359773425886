import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import concat from 'lodash/concat';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import WidgetLoader from '../../../../components/widgets/Loader';
import ProductionSumItem from './ProductionSumItem';
import { useSelector } from 'react-redux';
import {
  allTimeSum,
  lastMonthSum,
  thisMonthActual,
} from '../../../../helpers/performance-data';

function LoggerProduction(props) {
  const { meters } = props;
  const { loading } = useSelector((state) => state.meters);
  const [productionSums, setProductionSums] = useState({
    today: 0,
    thisMonth: 0,
    lastMonth: 0,
    allTime: 0,
  });

  useEffect(() => {
    const thisMonth = thisMonthActual(meters);
    const sums = {
      today: reduce(
        meters,
        (acc, meter) => acc + get(meter, 'energy_stats.today', 0),
        0
      ),
      thisMonth,
      lastMonth: lastMonthSum(
        concat(
          map(meters, (meter) => meter.energy_stats.production),
          map(meters, (meter) => meter.energy_stats.adjusted_production)
        )
      ),
      allTime:
        allTimeSum(
          concat(
            map(meters, (meter) => meter.energy_stats.production),
            map(meters, (meter) => meter.energy_stats.adjusted_production)
          )
        ) + thisMonth,
    };

    setProductionSums(sums);
  }, [meters]);

  return (
    <Card
      raised
      sx={{
        height: '100%',
        borderBottom: (theme) => `10px solid ${theme.veregy_colors.orange}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        pb: 0.5,
        px: 1,
        pt: 2,
      }}>
      <Grid container spacing={1} sx={{ px: 2 }}>
        <ProductionSumItem label='Today' value={productionSums.today} />
        <ProductionSumItem
          label='This Month'
          value={productionSums.thisMonth}
        />
        <ProductionSumItem
          label='Last Month'
          value={productionSums.lastMonth}
        />
        <ProductionSumItem label='All Time' value={productionSums.allTime} />
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography align='left' variant='h5'>
          Production Sums
        </Typography>
        {loading && <WidgetLoader />}
      </Box>
    </Card>
  );
}

LoggerProduction.propTypes = {
  meters: PropTypes.array.isRequired,
};

export default LoggerProduction;
