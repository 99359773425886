import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  compileCorrectedPerformanceIndexes,
  compilePerformanceIndexes,
} from '../../helpers/performance-data';
import ProductionIndexBar from './ProductionIndexBar';
import WidgetLoader from './Loader';
import InfoButton from '../charts/buttons/InfoButton';
function ProductionIndex(props) {
  const {
    sites,
    meters,
    inverters,
    sensors,
    loading,
    expectationType,
    setExpectationType,
    disabled,
  } = props;

  const [thisMonthPerformance, setThisMonthPerformance] = useState({
    actual: 0,
    expected: 0,
  });
  const [lastMonthPerformance, setLastMonthPerformance] = useState({
    actual: 0,
    expected: 0,
  });
  const [trailingPerformance, setTrailingPerformance] = useState({
    actual: 0,
    expected: 0,
  });

  const [warnings, setWarnings] = useState([]);

  useEffect(() => {
    if (expectationType === 'modeled') {
      const _meters = cloneDeep(meters);
      let expectations = map(sites, (site) => {
        return get(site, 'modeled_expectations');
      });

      const { thisMonth, lastMonth, trailing12Month } =
        compilePerformanceIndexes(_meters, expectations);

      setThisMonthPerformance(thisMonth);
      setLastMonthPerformance(lastMonth);
      setTrailingPerformance(trailing12Month);
    } else if (expectationType === 'corrected') {
      let expectations = map(sites, (site) => {
        return get(site, 'wc_expectations');
      });
      const { thisMonth, lastMonth, trailing12Month } =
        compileCorrectedPerformanceIndexes(expectations);

      setThisMonthPerformance(thisMonth);
      setLastMonthPerformance(lastMonth);
      setTrailingPerformance(trailing12Month);
    }
  }, [meters, sites, expectationType]);

  useEffect(() => {
    let _warnings = [];

    map(sites, (site) => {
      let siteMeterIds = map(
        filter(meters, { site_id: site?.site_id }),
        (meter) => meter.meter_id
      );

      let siteInverters = filter(inverters, (inverter) =>
        siteMeterIds.includes(inverter.meter_id)
      );
      let siteSensors = filter(sensors, (sensor) =>
        siteMeterIds.includes(sensor.meter_id)
      );

      let acSize = reduce(
        siteInverters,
        (acc, inverter) => acc + get(inverter, 'ac_size', 0),
        0
      );
      let dcSize = reduce(
        siteInverters,
        (acc, inverter) => acc + get(inverter, 'dc_size', 0),
        0
      );

      if (acSize === 0) {
        _warnings.push(`Inverters for ${site?.name} have no AC size defined`);
      }

      if (dcSize === 0) {
        _warnings.push(`Inverters for ${site?.name} have no DC size defined`);
      }

      if (siteSensors.length === 0) {
        _warnings.push(`No sensors defined for ${site?.name}`);
      }
    });

    setWarnings(_warnings);
  }, [sites, meters, inverters, sensors]);

  const renderWarnings = () => {
    return (
      <Tooltip
        title={
          <List sx={{ p: 0 }}>
            {map(warnings, (warning, idx) => {
              return (
                <ListItemText
                  key={idx}
                  slotProps={{
                    primary: { variant: 'caption' },
                  }}>
                  {warning}
                </ListItemText>
              );
            })}
          </List>
        }
        placement='left'>
        <Badge
          badgeContent={warnings.length}
          color='error'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <Typography align='left' variant='h5'>
            Production Index
          </Typography>
        </Badge>
      </Tooltip>
    );
  };

  return (
    <Card
      raised
      sx={{
        height: '100%',
        borderBottom: (theme) => `10px solid ${theme.veregy_colors.green}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        px: 1,
        pt: 1,
        pb: 0.5,
      }}>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12 }}>
          <Typography align='center'>
            {expectationType === 'modeled'
              ? 'Modeled Performance'
              : 'Weather Corrected Performance'}
          </Typography>
        </Grid>
        <ProductionIndexBar
          actual={thisMonthPerformance.actual}
          expected={thisMonthPerformance.expected}
          label='This Month'
          loading={loading}
        />
        <ProductionIndexBar
          actual={lastMonthPerformance.actual}
          expected={lastMonthPerformance.expected}
          label='Last Month'
          loading={loading}
        />
        <ProductionIndexBar
          actual={trailingPerformance.actual}
          expected={trailingPerformance.expected}
          label='Trailing 12-month'
          loading={loading}
        />
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {warnings.length > 0 && expectationType === 'corrected' ? (
          renderWarnings()
        ) : (
          <Typography align='left' variant='h5'>
            Production Index
          </Typography>
        )}

        <div>
          {!disabled && !loading && (
            <Tooltip
              title={
                expectationType === 'modeled'
                  ? 'Switch to weather corrected data'
                  : 'Switch to modeled data'
              }
              placement='left'>
              <IconButton
                sx={{ height: 30, width: 30 }}
                onClick={() =>
                  setExpectationType(
                    expectationType === 'modeled' ? 'corrected' : 'modeled'
                  )
                }>
                <FontAwesomeIcon
                  icon={[
                    'fal',
                    expectationType === 'modeled' ? 'thunderstorm-sun' : 'bolt',
                  ]}
                />
              </IconButton>
            </Tooltip>
          )}
          {loading ? (
            <WidgetLoader />
          ) : (
            <InfoButton
              title='Production Index'
              content={
                <>
                  <Typography variant='body2' color='textSecondary'>
                    Performance data is used to calculate a Production Index
                    (PI).
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    Production Index = actual kWh / expected kWh
                  </Typography>
                  {!disabled ? (
                    <Typography variant='body2' color='textSecondary'>
                      The button next to this info button will switch back &
                      forth from modeled performance to weather corrected
                      performance data.
                    </Typography>
                  ) : null}
                </>
              }
            />
          )}
        </div>
      </Box>
    </Card>
  );
}

ProductionIndex.propTypes = {
  sites: PropTypes.array.isRequired,
  meters: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  expectationType: PropTypes.string,
  setExpectationType: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ProductionIndex;
