import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { toastr } from '../../components/CustomToast';

const getMeters = createAsyncThunk(
  'meters/getMeters',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().meters;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const meters = await new WebAPIClient().GET('/resource/meters');
    return { data: meters };
  }
);

const putMeter = createAsyncThunk(
  'meters/putMeter',
  async (meter, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: meters } = getState().meters;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedMeter = await new WebAPIClient().PUT(
        `/resource/meters/${meter.org_id}/${meter.meter_id}`,
        meter
      );

      let _meters = cloneDeep(meters);
      remove(_meters, { meter_id: get(updatedMeter, 'meter_id') });

      toastr.success({ title: 'Meter updated' });
      return { data: concat(_meters, updatedMeter) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getMeters, putMeter };
