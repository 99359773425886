import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import head from 'lodash/head';

import Grid from '@mui/material/Grid2';

import useView from '../../../store/hooks/useView';
import ViewStack from '../../../components/ViewStack';
import {
  ESTIMATED,
  METER,
  PERFORMANCE,
  PRODUCTION,
  WEATHER,
} from '../../../components/charts/analysis/views';
import ProductionProfileChartContainer from '../../../components/charts/analysis/ProductionProfileChart/Container';
import PerformanceChartContainer from '../../../components/charts/analysis/PerformanceChart/Container';
import EstimatedChartContainer from '../../../components/charts/analysis/EstimatedChart/Container';
import WeatherStationChartContainer from '../../../components/charts/analysis/WeatherStationChart/Container';
import MeterChart from '../../../components/charts/analysis/MeterChart';

function AnalysisPage(props) {
  const { resource, timezone, sites, meters, inverters, sensors } = props;
  const [views, setViews] = useState([]);
  const selectedView = useView(views);

  useEffect(() => {
    const type = get(resource, 'type_', 'organization');
    if (type === 'organization' && get(resource, 'is_portfolio', false)) {
      setViews([PERFORMANCE]);
    } else if (type === 'organization') {
      setViews([PRODUCTION, PERFORMANCE, WEATHER]);
    } else if (type === 'site') {
      setViews([PRODUCTION, PERFORMANCE, ESTIMATED, WEATHER]);
    } else if (type === 'logger') {
      setViews([PRODUCTION, WEATHER]);
    } else if (type === 'meter') {
      setViews([PRODUCTION, METER]);
    }
  }, [resource]);

  return (
    <Grid
      container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ mt: 1, px: 1, width: '100%' }}>
      <Grid size={{ xs: 12 }}>
        <ViewStack selectedView={selectedView} views={views} />
      </Grid>
      {selectedView === PRODUCTION && (
        <ProductionProfileChartContainer
          defaultTimezone={timezone}
          orgId={resource.org_id}
          meters={meters}
          inverters={inverters}
        />
      )}
      {selectedView === PERFORMANCE && (
        <PerformanceChartContainer sites={sites} meters={meters} />
      )}
      {selectedView === ESTIMATED && (
        <EstimatedChartContainer
          defaultTimezone={timezone}
          site={head(sites)}
        />
      )}
      {selectedView === WEATHER && (
        <WeatherStationChartContainer
          defaultTimezone={timezone}
          meters={meters}
          sensors={sensors}
        />
      )}
      {selectedView === METER && (
        <MeterChart site={head(sites)} meter={resource} />
      )}
    </Grid>
  );
}

AnalysisPage.propTypes = {
  resource: PropTypes.object,
  timezone: PropTypes.object,
  sites: PropTypes.array,
  meters: PropTypes.array,
  inverters: PropTypes.array,
  sensors: PropTypes.array,
};

export default AnalysisPage;
