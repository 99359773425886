import React from 'react';

import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const ValuesList = (props) => {
  const { title, values } = props;
  return (
    <Card raised>
      <List>
        <ListItemText
          primary={title}
          slotProps={{
            primary: { align: 'center', fontWeight: 'bold' },
          }}
        />
        {values.map((item, idx) => {
          return (
            <ListItem key={item.measure_name + idx}>
              <ListItemText secondary={item.measure_name} />
              <ListItemText
                primary={item.value}
                slotProps={{
                  primary: { align: 'right' },
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
};

export default ValuesList;
