import React from 'react';
import { useSelector } from 'react-redux';

import useLogger from '../../../../store/hooks/useLogger';
import useSite from '../../../../store/hooks/useSite';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import AnalysisPage from '../../Analysis';

export default function Analysis() {
  const { id } = useSelector((state) => state.pages.logger);
  const logger = useLogger(id);
  const site = useSite(logger.site_id);
  const { meters, inverters, sensors } = useLoggerDevices(id);

  return (
    <AnalysisPage
      timezone={site.timezone}
      resource={logger}
      sites={[site]}
      meters={meters}
      inverters={inverters}
      sensors={sensors}
    />
  );
}
