import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';

const COPY_TEXT_BEFORE = 'Copy to Clipboard';
const COPY_TEXT_AFTER = 'Copied';

const ClipboardField = forwardRef((props, ref) => {
  const {
    id,
    value,
    label,
    buttonSize = 'medium',
    clipboardPosition = 'end',
    disabled = false,
    disableUnderline = true,
    ...innerProps
  } = props;
  const [tooltipText, setTooltipText] = useState(COPY_TEXT_BEFORE);

  const adornmentPosition =
    clipboardPosition === 'end' ? 'endAdornment' : 'startAdornment';

  function copyToClipboard(e) {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value).then(() => {
        setTooltipText(COPY_TEXT_AFTER);
      });
    }
  }

  return (
    <TextField
      inputRef={ref}
      id={id}
      value={value}
      label={label}
      fullWidth
      variant='standard'
      slotProps={{
        input: {
          readOnly: true,
          disableUnderline,
          sx: (theme) => ({
            color: disabled ? theme.veregy_colors.grey : 'inherit',
          }),
          [adornmentPosition]: value && (
            <InputAdornment position='start'>
              <ClickAwayListener
                onClickAway={() => setTooltipText(COPY_TEXT_BEFORE)}>
                <Tooltip
                  disableFocusListener
                  title={tooltipText}
                  placement='top'
                  onClose={() => {
                    setTimeout(() => {
                      setTooltipText(COPY_TEXT_BEFORE);
                    }, 250);
                  }}>
                  <IconButton
                    sx={{ width: 30, height: 30 }}
                    size={buttonSize}
                    onClick={copyToClipboard}>
                    <FontAwesomeIcon icon={['fal', 'clipboard']} />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </InputAdornment>
          ),
          ...innerProps,
        },
      }}
    />
  );
});

ClipboardField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  buttonSize: PropTypes.oneOf(['small', 'medium']),
  clipboardPosition: PropTypes.oneOf(['start', 'end']),
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
};

export default ClipboardField;
