import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import Box from '@mui/material/Box';

import useSite from '../../../../store/hooks/useSite';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import InvertersProfileChart from '../../../../components/charts/preview/InvertersProfileChart';
import SelectInverter from '../../../../components/charts/selectors/SelectInverter';
import AlarmPreviewLink from '../../../../components/AlarmPreviewLink';

function InvertersProfileCard({ logger }) {
  const site = useSite(logger.site_id);
  const { inverters, alarms } = useLoggerDevices(logger?.logger_id);

  if (inverters.length === 0) return null;
  const activeAlarms = filter(alarms, (alarm) => {
    return alarm.device_id.startsWith('inverter') && alarm.status;
  });
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        backgroundImage: (theme) => theme.vars.overlays[8],
        boxShadow: (theme) => theme.shadows[4],
        borderRadius: 1,
        px: 1,
        pt: 1,
        pb: 0.5,
        width: '100%',
      }}>
      <AlarmPreviewLink alarms={activeAlarms}>
        <SelectInverter inverters={inverters} />
      </AlarmPreviewLink>
      <InvertersProfileChart
        site={site}
        logger={logger}
        inverters={inverters}
      />
    </Box>
  );
}

InvertersProfileCard.propTypes = {
  logger: PropTypes.object.isRequired,
};

export default InvertersProfileCard;
