import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import VeregyIcon from './VeregyIcon';

export default function HeadlineContent() {
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        backgroundImage: (theme) => theme.vars.overlays[8],
        boxShadow: (theme) => theme.shadows[4],
        borderRadius: 1,
        px: 2,
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
      }}>
      <Typography variant='h5' align='left'>
        Illuminate your solar investment with real-time tracking, analytics, and
        fault detection.
      </Typography>
      <Box
        component='div'
        sx={{
          mt: 3.25,
          mb: 2.5,
          width: '100%',
          maxWidth: '400px',
          display: 'flex',
          justifyContent: 'space-around',
          alignSelf: 'center',
          alignItems: 'center',
        }}>
        <VeregyIcon height={100} name='Solar Panel' />
        <FontAwesomeIcon
          icon={['fal', 'long-arrow-right']}
          size='4x'
          // color={theme.palette.grayAccent}
        />
        <VeregyIcon height={100} name='Dashboard' />
      </Box>
    </Box>
  );
}
