import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import head from 'lodash/head';

const useTab = (tabs) => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(head(tabs));

  useEffect(() => {
    const _tab = head(location.hash.slice(1).split('?'));
    if (tabs.includes(_tab)) {
      setSelectedTab(_tab);
    } else {
      const defaultTab = head(tabs);
      setSelectedTab(defaultTab);
    }
  }, [tabs, location.hash]);

  return selectedTab;
};

export default useTab;
