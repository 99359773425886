import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const INTERVALS = [
  { name: '15 min', minutes: 15 },
  { name: '1 h', minutes: 60 },
  { name: '4 h', minutes: 240 },
  { name: '12 h', minutes: 720 },
  { name: '24 h', minutes: 1440 },
];

function SelectInterval(props) {
  const { selectedInterval, setSelectedInterval } = props;
  const theme = useTheme();

  const [interval, setInterval] = useState(INTERVALS[0]);
  const [intervals] = useState(INTERVALS);

  useEffect(() => {
    setInterval(selectedInterval);
  }, [selectedInterval]);

  const handleSelect = (e) => {
    e.preventDefault();
    let _interval = find(intervals, { minutes: e.target.value });
    setSelectedInterval(_interval);
  };

  return (
    <FormControl variant='standard'>
      <InputLabel
        id='timezone-label'
        sx={{ textAlign: 'center', width: '6rem' }}>
        Interval
      </InputLabel>
      <Select
        disableUnderline
        labelId='timezone-label'
        value={interval.minutes}
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={interval.name}
            slotProps={{
              primary: { align: 'center' },
            }}
          />
        )}
        onChange={handleSelect}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          slotProps: {
            paper: { sx: { minWidth: 'unset !important' } },
          },
        }}
        sx={{
          minWidth: '4rem',
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        {intervals.map((interval) => {
          return (
            <MenuItem
              key={interval.minutes}
              value={interval.minutes}
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}>
              {interval.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectInterval.propTypes = {
  selectedInterval: PropTypes.object,
  setSelectedInterval: PropTypes.func,
};

export default SelectInterval;
