import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import lastIndexOf from 'lodash/lastIndexOf';

import { formatTimestamp } from '../../../../helpers/date';
import BaseTooltip from '../../tooltips/BaseTooltip';

function Tooltip({ active, payload, label, timezone }) {
  const deviceName = (resource) => {
    let deviceId = resource.dataKey;
    let attrName = deviceId.substring(lastIndexOf(deviceId, ':') + 1);

    return attrName;
  };

  const deviceValue = (resource) => {
    let deviceId = resource.dataKey;
    let unit = '';
    let attrName = deviceId.substring(lastIndexOf(deviceId, ':') + 1);

    switch (attrName) {
      case 'Radiation':
        unit = 'W/m^2';
        break;

      case 'PanelTemp':
        unit = '°C';
        break;

      default:
        break;
    }

    return `${numeral(resource.value.toPrecision(2)).format(
      '0,0.[000]'
    )} ${unit}`;
  };

  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={formatTimestamp(Number(label), timezone, 'MMM Do h:mm A')}
      getName={deviceName}
      getValue={deviceValue}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
};

export default Tooltip;
